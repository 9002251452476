import { useEffect, useState } from 'react';

export const AD_VISIBILITY_THRESHOLD = 0.8;

export function useAdVisibility(containerId: string) {
  const [isAlmostVisible, setIsAlmostVisible] = useState(false);
  const [isRefreshable, setIsRefreshable] = useState(false);

  useEffect(() => {
    const adElement = document.getElementById(containerId);
    if (!adElement) {
      return;
    }
    const isVisibleObserver = new IntersectionObserver(
      ([entry]) => {
        setIsAlmostVisible(entry.isIntersecting);
      },
      { threshold: [0], rootMargin: '72px' },
    );
    const isRefreshableObserver = new IntersectionObserver(
      ([entry]) => {
        setIsRefreshable(entry.intersectionRatio >= AD_VISIBILITY_THRESHOLD);
      },
      { threshold: [AD_VISIBILITY_THRESHOLD] },
    );
    isVisibleObserver.observe(adElement);
    isRefreshableObserver.observe(adElement);
    return () => {
      isVisibleObserver.unobserve(adElement);
      isRefreshableObserver.unobserve(adElement);
    };
  }, [containerId]);

  return { isAlmostVisible, isRefreshable };
}
