import { FC, ReactNode, useMemo } from 'react';
import { memo } from '../../util/memo';
import { Configure, UseConfigureProps } from 'react-instantsearch';
import { CustomHitsPreempted } from './CustomHitsPreempted';
import { RefreshHits } from './RefreshHits';
import Box from '@mui/material/Box';
import isEqual from 'fast-deep-equal/react';
import type { Required } from 'utility-types';
import { PreemptStateProvider } from '../../contexts/algolia/PreemptStateContext';
import { PreemptedInstantSearch } from './PreemptedInstantSearch';
import { OrNode } from '../../../functions/src/types/Hit';
import { EventHit } from './catalog-wrappers/EventsCalendar';

export type CatalogWrapperProps<THit> = {
  hits: THit[];
  onLoadMore: () => void;
  header?: ReactNode;
  transformHits?: (hits: EventHit<Date>[]) => OrNode<EventHit<Date>>[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RenderCatalogWrapper = FC<CatalogWrapperProps<any>>;

export type AlgoliaLayoutProps = {
  CatalogWrapper: RenderCatalogWrapper;
  configureOptions: Required<UseConfigureProps, 'filters'>;
  index: string;
};

const clean = (configureOptions: Required<UseConfigureProps, 'filters'>) => {
  const { index: __, ...cleaned } = configureOptions;
  return cleaned;
};

const AlgoliaLayoutUnmemoized = ({
  CatalogWrapper,
  configureOptions,
  index,
}: AlgoliaLayoutProps) => {
  const configureOptionsCleaned = useMemo(() => {
    return clean(configureOptions);
  }, [configureOptions]);
  const { filters } = configureOptions;

  return (
    <PreemptStateProvider>
      <PreemptedInstantSearch indexName={index}>
        <RefreshHits filters={filters}>
          <Configure {...configureOptionsCleaned} />
          <Box
            sx={{
              height: '100%',
              animation: 'fadeIn ease 500ms',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '3%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          >
            <CustomHitsPreempted CatalogWrapper={CatalogWrapper} />
          </Box>
        </RefreshHits>
      </PreemptedInstantSearch>
    </PreemptStateProvider>
  );
};

export const AlgoliaLayout = memo(AlgoliaLayoutUnmemoized, (prev, next) => {
  return isEqual(prev.configureOptions, next.configureOptions);
});
