// The containerId constants follow the format "location-device-number" or "location-any-number" when the device is not specified.

export const GAME_PAGE_AD = 'game-any-1' as const;
export const GAME_PAGE_AD_MOBILE = 'game-mobile-1' as const;

export const GAME_CAROUSEL_AD = 'gameCarousel-any-1' as const;

export const MESSAGING_AD_1 = 'messaging-any-1' as const;
export const MESSAGING_AD_2 = 'messaging-any-2' as const;

export const PROFILE_PAGE_AD_1 = 'profile-any-1' as const;
export const PROFILE_PAGE_AD_2 = 'profile-any-2' as const;

export const FRIENDS_ADD_AD_1 = 'friendsAdd-any-1' as const;
export const FRIENDS_ADD_AD_DESKTOP = 'friendsAdd-desktop-1' as const;

export const FRIENDS_EDIT_AD_1 = 'friendsEdit-any-1' as const;
export const FRIENDS_EDIT_AD_DESKTOP = 'friendsEdit-desktop-1' as const;

export const FRIENDS_VIEW_AD_1 = 'friendsView-any-1' as const;
export const FRIENDS_VIEW_AD_MOBILE = 'friendsView-mobile-1' as const;

export const CHANNEL_MANAGER_AD_DESKTOP = 'channelManager-desktop-1' as const;

export const BRACKET_AD_MOBILE = 'bracket-mobile-1' as const;
export const BRACKET_AD = 'bracket-any-1' as const;

export const OVERVIEW_AD = 'overview-any-1' as const;

export const REGISTRATION_AD = 'registration-any-1' as const;

export const PARTICIPANTS_AD = 'participants-any-1' as const;

// USING BRACKET INSTEAD: export const HEATS_AD = 'heats-any-1' as const;

export const CALENDAR_AD = 'calendar-any-1' as const;
