import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import OtpInput from 'react-otp-input';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import {
  useAuthSubmit,
  VERIFICATION_CODE_LENGTH,
} from '../../../contexts/AuthSubmitContext';
import { ResendCodeText } from '../ResendCodeText';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';

const AuthenticationEnterConfirmationCodeDialogUnmemoized = () => {
  const theme = useTheme();
  const { onInputChange, userCredentials, isValidated, errorMessage, signIn } =
    useAuthSubmit();
  const { confirmationCode: invalidConfirmationCodeMessage } = errorMessage;
  const { confirmationCode: isValidConfirmationCode } = isValidated;
  const { confirmationCode } = userCredentials;

  return (
    <Stack sx={{ width: '100%' }}>
      <DialogAuthenticationNavBar />
      <DialogBodyStandard
        title={'Enter Confirmation Code'}
        description={
          <Stack spacing={2}>
            <Box sx={{ mb: 2 }}>
              <OtpInput
                value={confirmationCode}
                onChange={(value: string) => {
                  return onInputChange('confirmationCode', value);
                }}
                renderInput={({ value, ...props }) => {
                  return <input value={value} {...props} />;
                }}
                numInputs={VERIFICATION_CODE_LENGTH}
                containerStyle={{
                  width: '100%',
                  gap: 10,
                }}
                inputStyle={{
                  height: '94px',
                  color: theme.palette.text.primary,
                  width: '75px',
                  borderRadius: '8px',
                  fontSize: '40px',
                  backgroundColor: theme.palette.background.elevation[12],
                  border: '2px solid #0064E9',
                }}
              />
            </Box>
            {!!invalidConfirmationCodeMessage && (
              <Box textAlign={'center'}>
                <Typography
                  variant="body2"
                  color={theme.palette.error.main}
                  textAlign={'center'}
                >
                  {invalidConfirmationCodeMessage}
                </Typography>
              </Box>
            )}
            <ResendCodeText />
          </Stack>
        }
      >
        <LoadingButton
          variant="contained"
          size="large"
          onClick={signIn}
          disabled={!isValidConfirmationCode}
        >
          Submit Code
        </LoadingButton>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEnterConfirmationCodeDialog = memo(
  AuthenticationEnterConfirmationCodeDialogUnmemoized,
);
