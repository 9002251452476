import {
  AuthenticationInput,
  AuthenticationInputProps,
} from './AuthenticationInput';
import { useState } from 'react';
import { memo } from '../../util/memo';
import { PasswordInput, useAuthSubmit } from '../../contexts/AuthSubmitContext';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { camelToTitle } from '../../../functions/src/util/Strings';

export type PasswordInputProps = {
  passwordType: PasswordInput;
  errorMessage?: string;
} & AuthenticationInputProps;
const PasswordUnmemoized = ({
  passwordType,
  errorMessage,
  ...rest
}: PasswordInputProps) => {
  const { onInputChange } = useAuthSubmit();
  const [showPassword, setShowPassword] = useState(false);
  const clickShowPassword = () => {
    return setShowPassword(!showPassword);
  };
  const mouseDownPassword = () => {
    return setShowPassword(!showPassword);
  };
  const passwordLabel = camelToTitle(passwordType);
  return (
    <AuthenticationInput
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        return onInputChange(passwordType, event.currentTarget.value);
      }}
      helperText={errorMessage || undefined}
      label={passwordLabel}
      type={showPassword ? 'text' : 'password'}
      name={passwordType}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={clickShowPassword}
              onMouseDown={mouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
export const Password = memo(PasswordUnmemoized);
