import { memo } from '../util/memo';
import { ImageOptimized, ImageOptimizedProps } from './image/ImageOptimized';
import { Link } from './Link';
import { OptionalWrapper } from './OptionalWrapper';

export type LogoProps = Omit<ImageOptimizedProps, 'alt' | 'src'> & {
  asLink?: boolean;
};

export const BLUMINT_LOGO_URL = '/assets/images/logo.svg';

export function LogoUnmemoized({ asLink = true, ...props }: LogoProps) {
  return (
    <OptionalWrapper doWrap={asLink} Wrapper={<Link href="/" />}>
      <ImageOptimized src={BLUMINT_LOGO_URL} alt={'BluMint Logo'} {...props} />
    </OptionalWrapper>
  );
}
export const Logo = memo(LogoUnmemoized);
