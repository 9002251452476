import { useCallback, useState } from 'react';

export const useLoadingHandler = <T extends unknown[]>(
  handler?: (...params: T) => Promise<void>,
): [boolean, (...params: T) => Promise<void>] => {
  const [loading, setLoading] = useState(false);

  const asyncHandler = useCallback(
    async (...params: T) => {
      if (!loading) {
        setLoading(true);
        try {
          await Promise.resolve(handler?.(...params));
        } catch (error) {
          console.error(error);
        }
        setLoading(false);
      }
    },
    [handler, loading],
  );

  return [loading, asyncHandler];
};
