import Typography from '@mui/material/Typography';

import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';

export type TextOverlayAvatarProps = {
  text: string;
  sx?: SxProps;
};

const TextOverlayAvatarUnmemoized = ({
  text,
  sx = {},
}: TextOverlayAvatarProps) => {
  return (
    <Typography
      variant="body1"
      sx={{
        position: 'absolute',
        wordBreak: 'break-word',
        textAlign: 'center',
        borderRadius: '50%',
        background: 'rgba(0, 0, 0, 0.45)',
        pointerEvents: 'none',
        fontWeight: 600,
        lineHeight: '14px',
        top: 0,
        ml: '1px',
        mt: '1px',
        pt: '6px',
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export const TextOverlayAvatar = memo(TextOverlayAvatarUnmemoized);
