import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../util/memo';
import { LoadingButton } from '../buttons/LoadingButton';
import { useSendConfirmationCode } from '../../hooks/auth/useSendConfirmationCode';
import { useTheme } from '@mui/material/styles';

export const ResendCodeTextUnmemoized = () => {
  const theme = useTheme();
  const { sendConfirmationCode, secondsRemaining } = useSendConfirmationCode(
    {},
  );
  return (
    <Stack
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={'2px'}
    >
      <Typography variant="body1" color={'text.primary'}>
        Didn`t get a code?
      </Typography>
      <LoadingButton
        id="recaptcha"
        onClick={sendConfirmationCode}
        variant="text"
        disabled={secondsRemaining > 0}
        sx={{
          color: theme.palette.primary.main,
          textDecoration: 'underline',
          cursor: 'pointer',
          p: 0,
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        another one!
      </LoadingButton>
      {secondsRemaining > 0 && (
        <Typography variant="body1" color={'text.secondary'}>
          {secondsRemaining}s
        </Typography>
      )}
    </Stack>
  );
};

export const ResendCodeText = memo(ResendCodeTextUnmemoized);
