import {
  ChannelGroup,
  GroupFilter,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useUnreadMessageCount } from '../../contexts/UnreadMessageCountContext';
import { useAuth } from '../../contexts/AuthContext';
import { isSupport } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isTournament';
import { isDm } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isDm';
import { useCallback } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export const useParseChannelPreview = () => {
  const { unreadMessageCount } = useUnreadMessageCount();
  const { channelGroupId } = useActiveChannelGroup();
  const { uid } = useAuth();

  const parseChannelPreview = useCallback(
    (channelGroup: ChannelGroup<keyof GroupFilterMap, Date>) => {
      const {
        id,
        members,
        imgUrl,
        permanence,
        groupFilter,
        title: titleChannelGroup,
        date,
        phase,
      } = channelGroup;

      const isSelected = channelGroupId === id;
      const isTournamentChannel = isTournament<Date>(channelGroup);
      const isSupportChannel = isSupport<Date>(channelGroup);
      const isDmChannel = isDm<Date>(channelGroup);

      const channelGroupChannelIds = Object.keys(unreadMessageCount).filter(
        (key) => {
          return key.includes(String(id));
        },
      );

      const channelGroupChannels = channelGroupChannelIds.map((channelId) => {
        return unreadMessageCount[`${channelId}`];
      });

      const channelGroupUnreadCount = channelGroupChannels.reduce(
        (prev, curr) => {
          return prev + curr.total;
        },
        0,
      );

      const withoutSelf = members?.filter(({ userId }) => {
        return userId !== uid;
      });

      const imgUrls =
        members.length <= 1 || isSupportChannel
          ? [imgUrl]
          : members?.length > 2
          ? withoutSelf.map(({ image }) => {
              return image;
            })
          : [withoutSelf?.[0].image];

      const onlineUserId =
        withoutSelf.length === 1 ? withoutSelf[0].userId : undefined;

      const title = isDmChannel ? withoutSelf?.[0].username : titleChannelGroup;

      const tournamentId = isTournamentChannel
        ? (groupFilter[0] as GroupFilter<'Tournament'>[0]).tournamentId
        : undefined;

      return {
        channelGroupId: id,
        imgUrls,
        isSelected,
        channelGroupUnreadCount,
        groupFilter,
        onlineUserId,
        isDm: isDmChannel,
        isSupport: isSupportChannel,
        isRemovable: isSupportChannel,
        isTournament: isTournamentChannel,
        permanence,
        title,
        tournamentId,
        phase,
        date,
      };
    },
    [channelGroupId, unreadMessageCount, uid],
  );
  return { parseChannelPreview };
};
