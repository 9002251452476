import { generateNonAdjacentPositions } from '../../../functions/src/util/generateNonAdjacentPositions';

export const appendAdPositions = ({
  currentAdPositions,
  numNewElements,
  totalElements,
  adFrequency,
}: {
  currentAdPositions: Set<number>;
  numNewElements: number;
  totalElements: number;
  adFrequency: number;
}) => {
  const newAdPositions = generateNonAdjacentPositions({
    maxIndex: totalElements - 1,
    numEntities: Math.floor(numNewElements / adFrequency),
    allowFirstIndex: false,
    startIndex: totalElements - numNewElements,
  });
  const updatedAdPositions = new Set(currentAdPositions);

  newAdPositions.forEach((position) => {
    updatedAdPositions.add(position);
  });

  return updatedAdPositions;
};
