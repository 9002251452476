import { ComponentType, MemoExoticComponent, FC } from 'react';
import { memo } from '../memo';
import { useAdInjection } from '../../hooks/ads/useAdInjection';

export type HitsProps<THit> = {
  hits: THit[];
};

export type AdsInjectableComponent<THit, TProps extends HitsProps<THit>> =
  | ComponentType<TProps>
  | MemoExoticComponent<ComponentType<TProps>>;

/**
 * @remarks
 * You should avoid putting this inside a React Component body and instead put it
 * in the global scope.
 *
 * If you must use it inside a React Component body, you should useMemo.
 */
export type WithAdInjectionType<THit, TProps extends HitsProps<THit>> = {
  WrappedComponent: AdsInjectableComponent<THit, TProps>;
  adFrequency: number;
  width: number;
  height: number;
  baseContainerId: string;
  adjustable?: 'width' | 'height';
  borderRadius?: string;
};

export const withAdInjection = <THit, TProps extends HitsProps<THit>>({
  WrappedComponent,
  adFrequency,
  width,
  height,
  baseContainerId,
  adjustable,
  borderRadius,
}: WithAdInjectionType<THit, TProps>) => {
  const ComponentWithAdInjectionUnmemoized: FC<TProps> = ({
    hits,
    ...rest
  }) => {
    const injectHits = useAdInjection({
      adFrequency,
      adWidth: width,
      adHeight: height,
      baseContainerId,
      adjustable,
      borderRadius,
    });

    const injectedHits = injectHits(hits);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propsNew = { hits: injectedHits, ...rest } as any;
    return <WrappedComponent {...propsNew} />;
  };

  return memo(ComponentWithAdInjectionUnmemoized);
};
