import { useCallback, useState, memo, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LottieLoader } from '../LottieLoader';
import { SxProps } from '@mui/material/styles';

const PASSWORD_RESET_EMAIL_SENT =
  'Email sent! Please check your inbox and spam folder.';

export type PasswordResetButton = {
  email: string;
  text: string;
  sx?: SxProps;
};
export const PasswordResetButton = memo(function PasswordResetButtonUnmemoized({
  email,
  text,
  sx,
}: PasswordResetButton) {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const sendForgotPassword = useCallback(async () => {
    try {
      setClicked(true);
      const { sendAuthEmail } = await import(
        '../../firebaseCloud/mail/sendAuthEmail'
      );
      await sendAuthEmail({
        to: email,
        mode: 'resetPassword',
      });
      setEmailSent(true);
    } catch (e) {
      console.error(e);
      setEmailSent(false);
      setClicked(false);
    }
  }, [email]);

  const sendResetButton = useMemo(() => {
    return (
      <>
        {!clicked ? (
          <Typography
            onClick={sendForgotPassword}
            color="text.secondary"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            variant="body2"
          >
            {text}
          </Typography>
        ) : !emailSent ? (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LottieLoader sx={{ height: '16px', width: '16px' }} />
          </Box>
        ) : (
          <Typography color="text.secondary" variant="body2">
            {PASSWORD_RESET_EMAIL_SENT}
          </Typography>
        )}
      </>
    );
  }, [clicked, emailSent, sendForgotPassword, text]);

  return <Box sx={sx}>{sendResetButton}</Box>;
});
