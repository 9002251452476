import { useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { ClipboardShare, ClipboardShareProps } from './ClipboardShare';
import { shortenUrlFrom } from '../util/shortenUrlFrom';
import { track } from '@vercel/analytics';
import { updateKvWith } from '../util/updateKvWith';
import { memo } from '../util/memo';

export const ClipboardShareUrl = memo(function ClipboardShareUrlUnmemoized({
  value,
  onClick,
  ...props
}: ClipboardShareProps) {
  const { uidFull } = useAuth();
  const userId = useMemo(() => {
    return uidFull || 'loading';
  }, [uidFull]);

  const url = useMemo(() => {
    if (typeof value === 'string') {
      return value;
    }
    const valueNew = value();
    return valueNew;
  }, [value]);

  const generateUrlShort = useCallback(() => {
    return shortenUrlFrom(url);
  }, [url]);

  const shortenAndTrack = useCallback(async () => {
    const urlShort = generateUrlShort();
    track('shortUrlCreated', {
      userId,
      timestamp: Date.now(),
      urlShort,
    });
    await updateKvWith(urlShort, url);
    if (onClick) {
      await onClick();
    }
  }, [generateUrlShort, userId, url, onClick]);

  return (
    <ClipboardShare
      value={generateUrlShort}
      onClick={shortenAndTrack}
      {...props}
    />
  );
});
