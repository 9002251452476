import { useEffect } from 'react';
import { useAuthFlowDialog } from './auth/useAuthFlowDialog';
import { useRouterState } from './routing/useRouterState';
import { AUTH_MAIL_SEND_KEY } from '../contexts/VerifyEmailContext';
import { removeItem } from '../util/webStorage';
import { useAlertDialog } from './useAlertDialog';

export const INVALID_ACTION_CODE = 'INVALID_ACTION_CODE_DIALOG';
export const INVALID_ACTION_ERROR_CODE = 'invalid-action-code';
export const INVALID_CODE_TITLE = 'Invalid Verfication Code';
export const INVALID_CODE_DESCRIPTION =
  'It seems like the verification code used to authorize this email is no longer valid, please resend your verification email and try again.';

export const useActionHandler = () => {
  const { open: openAuthDialog } = useAuthFlowDialog();
  const [mode, setMode] = useRouterState({
    key: 'mode',
    location: 'queryParam',
  });
  const [oobCode, setOobCode] = useRouterState({
    key: 'oobCode',
    location: 'queryParam',
  });
  const { open: openAlert } = useAlertDialog(INVALID_ACTION_CODE);

  useEffect(() => {
    const handler = async () => {
      if (mode === 'verifyEmail' && !!oobCode) {
        try {
          const authImport = import('../config/firebase-client/auth');
          const { auth } = await authImport;
          const firebaseAuthImport = import('firebase/auth');
          const { applyActionCode } = await firebaseAuthImport;
          await applyActionCode(auth, oobCode);
          await auth.currentUser?.reload();
          removeItem(AUTH_MAIL_SEND_KEY);
          openAuthDialog({ entryDialog: 'Verifying Email Success' });
          setMode(undefined);
          setOobCode(undefined);
          return;
        } catch (error) {
          console.error(error);
          if (
            (error as { message?: string })?.message?.includes(
              INVALID_ACTION_ERROR_CODE,
            )
          ) {
            openAlert({
              title: INVALID_CODE_TITLE,
              description: INVALID_CODE_DESCRIPTION,
            });
          }
          return;
        }
      }
      if (mode === 'resetPassword') {
        openAuthDialog({ entryDialog: 'Reset Password' });
      }
    };
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
