import { useEffect, useState, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AvatarGroupNext } from '../../AvatarGroupNext';
import { ReplyProvider } from '../ReplyContext';
import dynamic from 'next/dynamic';
import { useTheme } from '@mui/material/styles';
import type { StreamChat as StreamChatType } from 'stream-chat';
import { toKebabCase } from '../../../util/string';
import { useAuth } from '../../../contexts/AuthContext';
import { LottieLoader } from '../../LottieLoader';
import Stack from '@mui/material/Stack';
import type { GroupFilter } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { toChannelId } from '../../../../functions/src/util/messaging/toChannelId';
import { ChannelGroupHeader } from './ChannelGroupHeader';
import { ActiveChannelGroup } from '../../../../functions/src/util/messaging/toActiveChannelGroup';
import { isPersonal } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isPersonal';
import { sortGroups } from '../../../util/messaging/sortGroups';
import { Chat as StreamChat } from 'stream-chat-react';

const ChannelGroup = dynamic(async () => {
  return (await import('./ChannelGroup')).ChannelGroup;
});

export type ChannelGroupActiveProps = {
  streamChatClient: StreamChatType | null;
  channelGroup: ActiveChannelGroup;
};

const ChannelGroupActiveUnmemoized = ({
  streamChatClient,
  channelGroup,
}: ChannelGroupActiveProps) => {
  const theme = useTheme();
  const { userData } = useAuth();
  const [profileId, setProfileId] = useState<string | undefined>();

  const {
    groupFilter,
    title,
    subtitle,
    imgUrls,
    hasMultipleChannels,
    isDmChannel,
    isSupportChannel,
    isTournamentChannel,
    isChannelCreated,
    permanence,
    memberIds,
  } = channelGroup;

  const { type } = groupFilter[0];

  const channelGroupTitleColor = `${
    isTournamentChannel ? 'warning' : 'primary'
  }.vertical`;

  const avatarBorderColor = isTournamentChannel
    ? theme.palette.warning.main
    : isSupportChannel
    ? theme.palette.primary.main
    : undefined;

  const avatarBorder = !!avatarBorderColor
    ? undefined
    : `2px solid ${theme.palette.background.elevationSolid[6]} !important`;

  useEffect(() => {
    if (!title || !isDmChannel) {
      return;
    }
    const userId = memberIds.filter((memberId) => {
      return memberId !== userData?.id;
    });
    if (!userId) {
    }
    setProfileId(userId[0]);
  }, [isDmChannel, memberIds, title, userData?.id]);

  const tournamentId = isTournamentChannel
    ? (groupFilter as GroupFilter<'Tournament'>)[0].tournamentId
    : undefined;

  const href = isDmChannel
    ? `/profile/${profileId}`
    : isTournamentChannel
    ? `/?event=Game/${toKebabCase(subtitle)}/Tournament/${tournamentId}`
    : undefined;

  const isPersonalChannelGroup = useMemo(() => {
    return isPersonal({
      ...channelGroup,
      imgUrl: imgUrls[0],
    });
  }, [channelGroup, imgUrls]);

  const groupFilterModified = useMemo(() => {
    return (
      isPersonalChannelGroup
        ? [
            {
              type,
              cid: `${type}:${toChannelId(groupFilter[0])}`,
            },
          ]
        : groupFilter
    ) as GroupFilter;
  }, [groupFilter, isPersonalChannelGroup, type]);

  return (
    <ReplyProvider>
      <ChannelGroupHeader
        title={title}
        subtitle={subtitle}
        gradientColor={channelGroupTitleColor}
        href={href}
        tournamentId={tournamentId}
        permanence={permanence}
        Figure={
          <AvatarGroupNext
            imgUrls={imgUrls}
            max={2}
            total={imgUrls.length}
            borderColor={avatarBorderColor}
            avatarProps={{ height: 42, width: 42 }}
            userId={profileId}
            showStatus={isDmChannel}
            sx={{
              '.MuiAvatar-root:nth-of-type(1)': {
                ml: imgUrls.length > 2 ? '-12px' : '-18px',
              },
              '.MuiAvatar-root': {
                border: avatarBorder,
                backgroundColor: theme.palette.action.disabledOpaque,
                fontSize: '16px',
              },
            }}
            badgeSx={{
              '& .MuiBadge-badge': {
                borderRadius: '50%',
                height: '12px',
                width: '12px',
                border: `2px solid ${theme.palette.background.elevation[6]}`,
                boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
              },
            }}
          />
        }
      >
        {isChannelCreated && !!streamChatClient ? (
          <StreamChat client={streamChatClient} theme="str-chat__theme-dark">
            <ChannelGroup
              groupFilter={groupFilterModified}
              hasMultipleChannels={hasMultipleChannels}
              sortGroups={sortGroups}
              tournamentId={tournamentId}
            />
          </StreamChat>
        ) : (
          <Stack
            justifyContent="center"
            alignSelf="center"
            sx={{ height: '100%', width: '48px' }}
          >
            <LottieLoader sx={{ height: '40px', width: '40px' }} />
          </Stack>
        )}
      </ChannelGroupHeader>
    </ReplyProvider>
  );
};

export const ChannelGroupActive = memo(ChannelGroupActiveUnmemoized);
