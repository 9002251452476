import {
  PLACEHOLDER_TEXT,
  REALTIME_CONFIGURATION_TEMPLATES,
} from '../../algoliaRealtime/templates';

export const PREEMPTIVE_FILTER_TEMPLATES = {
  ...REALTIME_CONFIGURATION_TEMPLATES,
  game: '_tags: Game/END_COLLECTION',
  guestlist: `_tags: Guestlist/END_COLLECTION AND _tags:${PLACEHOLDER_TEXT}`,
  waitlist: `_tags: Waitlist/END_COLLECTION AND _tags:${PLACEHOLDER_TEXT}`,
  participant: `_tags: Participant/END_COLLECTION AND _tags:${PLACEHOLDER_TEXT}`,
  user: '_tags: User/END_COLLECTION',
} as const;
export type PreemptiveFilterKey = keyof typeof PREEMPTIVE_FILTER_TEMPLATES;

export type PathSort = {
  path: string;
  sort?: {
    fieldPath: string;
    order: 'asc' | 'desc';
  };
};
export const PREEMPTIVE_PATHSORT_TEMPLATES: Record<
  PreemptiveFilterKey,
  PathSort
> = {
  friend: {
    path: `AlgoliaUser/${PLACEHOLDER_TEXT}/AlgoliaHitFriend`,
    sort: { fieldPath: 'mutualFriendsCountEstimate', order: 'desc' },
  },
  fam: {
    path: `AlgoliaUser/${PLACEHOLDER_TEXT}/AlgoliaHitFam`,
    sort: { fieldPath: 'mutualFriendsCountEstimate', order: 'desc' },
  },
  friendRequest: {
    path: `AlgoliaUser/${PLACEHOLDER_TEXT}/AlgoliaHitFriendRequest`,
    sort: { fieldPath: 'mutualFriendsCountEstimate', order: 'desc' },
  },
  game: {
    path: 'AlgoliaHitGame',
    sort: { fieldPath: 'priority', order: 'asc' },
  },
  guestlist: {
    path: `AlgoliaTournament/${PLACEHOLDER_TEXT}/AlgoliaHitGuestlist`,
    sort: {
      fieldPath: 'registrationTime',
      order: 'asc',
    },
  },
  waitlist: {
    path: `AlgoliaTournament/${PLACEHOLDER_TEXT}/AlgoliaHitWaitlist`,
    sort: {
      fieldPath: 'registrationTime',
      order: 'asc',
    },
  },
  participant: {
    path: `AlgoliaTournament/${PLACEHOLDER_TEXT}/AlgoliaHitParticipant`,
    sort: {
      fieldPath: 'registrationTime',
      order: 'asc',
    },
  },
  user: { path: 'AlgoliaHitUser' },
} as const;

export const HITS_PER_PAGE_DEFAULT = 20 as const;
