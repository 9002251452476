import { ReactNode, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useTheme } from '@mui/material/styles';
import { GradientTypography } from './gradients/GradientTypography';
import { GradientIcon } from './gradients/GradientIcon';
import Stack from '@mui/material/Stack';
import { memo } from '../util/memo';
import { useDialog } from '../hooks/useDialog';
import AccordionDetails from '@mui/material/AccordionDetails';

export const LIVESTREAM_INFO_DIALOG = 'livestream-info-dialog';

export type AccordionInfoProps = {
  title: string;
  body: ReactNode;
  openInDialog?: boolean;
};

const AccordionInfoUnmemoized: React.FC<AccordionInfoProps> = ({
  title,
  body,
  openInDialog = false,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { open } = useDialog(LIVESTREAM_INFO_DIALOG);

  const toggleAccordion = () => {
    if (openInDialog) {
      open({ title, children: body });
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <Stack>
      <Accordion variant="outlined" onChange={toggleAccordion}>
        <AccordionSummary
          sx={{
            background: theme.palette.background.elevation[11],
            borderRadius: '10px',
            border: 'none',
          }}
          expandIcon={
            expanded ? (
              <GradientIcon IconComponent={HighlightOffOutlinedIcon} />
            ) : (
              <GradientIcon IconComponent={InfoOutlinedIcon} />
            )
          }
        >
          <GradientTypography variant="h6" fontWeight={600}>
            {title}
          </GradientTypography>
        </AccordionSummary>
        {!openInDialog && <AccordionDetails>{body}</AccordionDetails>}
      </Accordion>
    </Stack>
  );
};

export const AccordionInfo = memo(AccordionInfoUnmemoized);
