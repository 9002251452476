const BASE_62_CHARS =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const MAX_VALUE_BYTE = 62 * 4 - 1;
const BASE_62_128_BIT = 22;
const BYTES_ALLOCATION = BASE_62_128_BIT * 2;

export function uuidv4Base62() {
  let autoId = '';
  while (autoId.length < BASE_62_128_BIT) {
    const bytes = new Uint8Array(BYTES_ALLOCATION);
    crypto.getRandomValues(bytes);
    for (let i = 0; i < bytes.length && autoId.length < BASE_62_128_BIT; i++) {
      // eslint-disable-next-line security/detect-object-injection
      const b = bytes[i];
      if (autoId.length < BASE_62_128_BIT && b <= MAX_VALUE_BYTE) {
        autoId += BASE_62_CHARS.charAt(b % 62);
      }
    }
  }
  return autoId;
}
