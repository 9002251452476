import { FC, useMemo } from 'react';
import { memo } from '../../util/memo';
import { CatalogWrapperProps } from './AlgoliaLayout';
import type { DocumentDataEverywhere } from '../../../functions/src/types/DocumentDataEverywhere';
import { buildPreemptionPathSort } from '../../../functions/src/util/algolia/preemption/buildPreemptionPathSort';
import { useFirestoreHits } from '../../hooks/algolia/useFirestoreHits';
import { useCustomHits } from '../../hooks/algolia/useCustomHits';
import { useInfiniteHits } from 'react-instantsearch';
import { usePreemptState } from '../../contexts/algolia/PreemptStateContext';

export type CustomHitsPreemptedProps = {
  CatalogWrapper: FC<
    Omit<CatalogWrapperProps<DocumentDataEverywhere>, 'header'>
  >;
};

export const CustomHitsPreemptedUnmemoized = ({
  CatalogWrapper,
}: CustomHitsPreemptedProps) => {
  const state = usePreemptState();

  const query = useMemo(() => {
    return state?.query;
  }, [state?.query]);

  const filters = useMemo(() => {
    return state?.filters;
  }, [state?.filters]);

  const hitsPerPage = useMemo(() => {
    return state?.hitsPerPage;
  }, [state?.hitsPerPage]);

  const preemptionPathSort = useMemo(() => {
    const isNoQuery = !query || query === '';
    return isNoQuery && filters ? buildPreemptionPathSort(filters) : undefined;
  }, [query, filters]);

  const firestoreHits = useFirestoreHits(
    preemptionPathSort,
    hitsPerPage,
    filters,
  );

  const infiniteHits = useInfiniteHits();
  const algoliaHits = useCustomHits(infiniteHits);
  const isLoadingInfiniteHits = useMemo(() => {
    const processingMs = infiniteHits.results?.processingTimeMS;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const serverMs = (infiniteHits.results as any)?.serverTimeMS as
      | number
      | undefined;
    return infiniteHits.items.length === 0 && !processingMs && !serverMs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    infiniteHits.hits.length,
    infiniteHits.results?.processingTimeMS,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    (infiniteHits.results as any)?.serverTimeMS,
  ]);

  const { hits, loadMore } = useMemo(() => {
    if (preemptionPathSort) {
      return firestoreHits;
    }
    if (firestoreHits.hits.length && isLoadingInfiniteHits) {
      return firestoreHits;
    }
    return algoliaHits;
  }, [algoliaHits, firestoreHits, isLoadingInfiniteHits, preemptionPathSort]);

  return <CatalogWrapper hits={hits} onLoadMore={loadMore} />;
};

export const CustomHitsPreempted = memo(CustomHitsPreemptedUnmemoized);
