export class Strings {
  public static format(format: string, ...args: string[]) {
    return format.replace(/{(\d+)}/g, function (match: string, number: number) {
      return typeof args[Number(number)] !== 'undefined'
        ? args[Number(number)]
        : match;
    });
  }
}

export const camelToTitle = (camelCase: string): string => {
  return camelCase
    .replace(/([A-Z])/g, (match) => {
      return ` ${match}`;
    })
    .replace(/^./, (match) => {
      return match.toUpperCase();
    })
    .trim();
};

export function toCamelCase(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => {
    return chr.toUpperCase();
  });
}
export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function lowercaseFirstLetter(word: string): string {
  return word.charAt(0).toLowerCase() + word.slice(1);
}

export function toTitleCase(
  str: string,
  options: { fromKebab: boolean } = { fromKebab: false },
): string {
  return str
    .split(options.fromKebab ? '-' : ' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export const extractPrefix = (pathname: string): string => {
  const withoutQueryString = pathname.split('?')[0] || pathname;
  const [prefix] = withoutQueryString.split('/').filter(Boolean);
  return prefix;
};

export const prependPrefix = (
  pathnameOriginal: string,
  uri: string,
): string => {
  const normalizedUrl = uri.replace(/(http:\/\/|https:\/\/)/i, '');
  const isExternal = normalizedUrl.split('/')[0].includes('.');

  if (isExternal) {
    return uri;
  }

  const prefix = extractPrefix(pathnameOriginal);
  if (uri.startsWith(`/${prefix}`)) {
    return uri;
  }

  return `/${prefix}${uri}`;
};

export function toKebabCase(str?: string): string {
  return (
    str
      ?.toLowerCase()
      .split(' ')
      .join('-')
      .replace(':', '-')
      .replace('--', '-')
      .replace(`'`, '') || ''
  );
}

export function truncateWithOverflow(toTruncate: string, maxLength: number) {
  return {
    truncated: toTruncate.substring(0, maxLength),
    overflow: toTruncate.substring(maxLength),
  };
}

export const ALPHABET =
  '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
