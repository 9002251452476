import type { RequestMessageData } from '../../../functions/src/callable/moralis/requestMessage.f';
import { DEFAULT_CHAIN_IDS } from '../defaultChainIds';

export async function messageToAuthenticate(account: string, chain: number) {
  const chainOrEth = !DEFAULT_CHAIN_IDS.includes(chain) ? 1 : chain;

  const userData: RequestMessageData = {
    address: account,
    chain: chainOrEth,
    networkType: 'evm',
  };
  try {
    const { requestMessage } = await import(`../../firebaseCloud`);
    const requestMessageResponse = await requestMessage(userData);
    //TODO: update types for requestMessage & issueToken
    const { data } = requestMessageResponse;

    return { error: false, message: data?.message };
  } catch (error) {
    return { error };
  }
}
type GetMoralisTokenParams = {
  message: string;
  signature: string;
};

export async function moralisTokenOf({
  message,
  signature,
}: GetMoralisTokenParams) {
  const { issueTokenMoralis } = await import(
    `../../firebaseCloud/auth/issueTokenMoralis`
  );

  const { data } = await issueTokenMoralis({
    message,
    signature,
    networkType: 'evm',
  });
  return data.token;
}
