import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { GradientButton } from '../gradients/GradientButton';
import { useAuthFlowDialog } from '../../hooks/auth/useAuthFlowDialog';

export const SignUpButton = () => {
  const { open: openAuthDialog } = useAuthFlowDialog();
  return (
    <GradientButton
      size="large"
      variant="outlined"
      onClick={openAuthDialog}
      startIcon={AccountBalanceWalletOutlinedIcon}
      sx={{
        whiteSpace: 'nowrap',
      }}
    >
      Sign Up
    </GradientButton>
  );
};
