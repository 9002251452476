import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDialog } from '../useDialog';
import { ChipUser } from '../../components/ChipUser';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { useAuth } from '../../contexts/AuthContext';
import { useIndexingHits } from '../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../functions/src/util/algoliaRealtime/fillTemplate';

export const REMOVE_FRIEND_DIALOG_ID = 'REMOVE_FRIEND_DIALOG';

export type UseRemoveFriendDialogParams = {
  username: string;
  imgUrl: string;
  userId: string;
};

export const useRemoveFriendDialog = ({
  username,
  imgUrl,
  userId,
}: UseRemoveFriendDialogParams) => {
  const { uid } = useAuth();
  const { open: openDialog, close } = useDialog(REMOVE_FRIEND_DIALOG_ID);
  const { remove } = useIndexingHits();

  const removeFriendFn = async () => {
    const { removeFriend } = await import('../../firebaseCloud');
    if (!uid) {
      return;
    }
    await removeFriend({ fromId: uid, toId: userId });
    const objectID = `Friendship/${[userId, uid].sort().join('-')}`;

    const friendTemplateFilledHash = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid),
    );
    const famTemplateFilledHash = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['fam'], uid),
    );
    remove(friendTemplateFilledHash, objectID);
    remove(famTemplateFilledHash, objectID);
  };

  const open = () => {
    openDialog({
      title: 'Remove Friend',
      description: (
        <Typography>
          Are you sure you want to remove
          <ChipUser
            username={username}
            avatarUrl={imgUrl}
            href={`/profile/${userId}`}
            sx={{ mx: 2 }}
          />
          as a friend?
        </Typography>
      ),
      showCloseIcon: true,
      children: (
        <Stack direction="row" spacing={2} width="100%" alignItems="center">
          <LoadingButton
            onClick={async () => {
              await removeFriendFn();
              close();
            }}
            variant="contained"
            color="error"
            fullWidth
          >
            Remove
          </LoadingButton>
          <Button fullWidth onClick={close} variant="contained" color="primary">
            Cancel
          </Button>
        </Stack>
      ),
    });
  };
  return { open, close };
};
