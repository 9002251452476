import type {
  ArrayTwoOrMore,
  ChannelFilters as StreamChannelFilters,
} from 'stream-chat';
import type {
  GroupFilterMap,
  GroupFilter,
} from '../../types/firestore/User/ChannelGroup';
import { withGetStreamMembers } from './mapId';

export const toChannelFilters = <TGroupKey extends keyof GroupFilterMap>(
  groupFilter: GroupFilter<TGroupKey>,
): StreamChannelFilters => {
  const groupFilterPrefixed = groupFilter.map(withGetStreamMembers);
  if (groupFilterPrefixed.length === 0) {
    return {};
  }
  if (groupFilterPrefixed.length === 1) {
    return groupFilterPrefixed[0];
  }
  return {
    $or: groupFilterPrefixed as ArrayTwoOrMore<StreamChannelFilters>,
  };
};
