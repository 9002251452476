/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';

export function useDatadog() {
  useEffect(() => {
    const handler = async () => {
      const { datadogRum } = await import('@datadog/browser-rum');

      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APP_ID!,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
        site: process.env.NEXT_PUBLIC_DATADOG_SITE,
        service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
        env: process.env.NODE_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: process.env.NEXT_PUBLIC_VERSION,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    };
    handler();
  }, []);
}
