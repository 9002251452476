import { useMemo, FC, useCallback, useEffect } from 'react';
import { memo } from '../../util/memo';
import { SxProps, useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import Stack from '@mui/material/Stack';
import { track } from '@vercel/analytics';
import { useAdRefresh } from '../../hooks/ads/useAdRefresh';
import { useDeviceType } from '../../hooks/useDeviceType';
import { AD_UNIT_IDS } from '../../../functions/src/util/ads/adapexAdUnits';
import { findClosest } from '../../../functions/src/util/ads/findClosest';
import { Adapex } from '../../util/ads/Adapex';
import { useAdBlock } from '../../contexts/AdBlockContext';

export const MIN_ADS_IMPRESSION_MS = 1000;

export type AdapexAdContainerProps = {
  baseContainerId: string;
  width: number;
  height: number;
  adjustable?: 'width' | 'height';
  sx?: SxProps;
  borderRadius?: string;
};

const AdapexAdContainerUnmemoized: FC<AdapexAdContainerProps> = ({
  baseContainerId,
  width,
  height,
  adjustable,
  sx,
  borderRadius = '0px',
}) => {
  const theme = useTheme();
  const { uid: userId } = useAuth();

  const deviceType = useDeviceType();
  const { spyAd } = useAdBlock();

  const {
    width: adjustedWidth,
    height: adjustedHeight,
    adUnitId,
    aspectRatio,
  } = findClosest(AD_UNIT_IDS, width, height, adjustable);

  const { containerId, isAlmostVisible } = useAdRefresh({
    baseContainerId,
    aspectRatio,
    adUnitId,
  });

  useEffect(() => {
    const showBanner = async () => {
      if (!isAlmostVisible) return;

      let spying: string | undefined = undefined;

      try {
        await Adapex.refreshIds(containerId);
        // await Adapex.processAdsOnPage();
      } catch (error) {
        console.error(
          'Encountered a Persona error while attempting to showBannerAd:',
          error,
        );
        const errorMessage = String(
          !!error && typeof error === 'object' && 'message' in error
            ? error.message
            : error,
        );
        spying = errorMessage;
        track('adLoadFailed', {
          containerId,
          adUnitId,
          errorMessage,
          ...(userId && { userId }),
          environment: process.env.NODE_ENV,
        });
      }

      setTimeout(() => {
        spyAd(spying);
      }, 100);
    };
    showBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlmostVisible, containerId, adUnitId, userId]);

  const adClick = useCallback(() => {
    const [location, , , contentId] = containerId.split('-');

    track('adClick', {
      ...(userId && { userId }),
      containerId,
      adUnitId,
      location,
      device: deviceType,
      ...(contentId && { contentId }),
    });
  }, [containerId, adUnitId, userId, deviceType]);

  const memoizedStack = useMemo(() => {
    return (
      <Stack
        data-aaad="true"
        data-aa-lazy-loaded="true"
        data-aa-adunit={adUnitId}
        id={containerId}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          div: {
            height: '100% !important',
            width: '100% !important',
            maxWidth: 'none !important',
            borderRadius: `${borderRadius} !important`,
          },
          'div > a': {
            height: '100% !important',
            width: '100% !important',
            maxWidth: 'none',
            borderRadius: `${borderRadius} !important`,
          },
          ...sx,
        }}
      />
    );
  }, [adUnitId, borderRadius, containerId, sx]);

  return (
    <Stack
      sx={{
        position: 'relative',
        width: `${adjustedWidth}px !important`,
        height: `${adjustedHeight}px !important`,
        minWidth: `${adjustedWidth}px`,
        minHeight: `${adjustedHeight}px`,
        background: theme.palette.background.lightGradient,
      }}
      key={containerId}
      onClick={adClick}
    >
      {memoizedStack}
    </Stack>
  );
};

export const AdapexAdContainer = memo(AdapexAdContainerUnmemoized);
