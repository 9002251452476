import { stableHash } from '../hash/stableHash';

export const didAppendElements = <TElement>(
  prevElements: TElement[],
  currentElements: TElement[],
) => {
  if (currentElements.length <= prevElements.length) {
    return false;
  }

  const newEndElements = currentElements.slice(prevElements.length);
  return newEndElements.every((element) => {
    return !prevElements.some((prevElement) => {
      if (typeof element === 'string' && typeof prevElement === 'string') {
        return element === prevElement;
      }
      return stableHash(element) === stableHash(prevElement);
    });
  });
};
