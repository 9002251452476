import stringify from 'json-stringify-safe';

export const MESSAGE_DEFAULT = 'NO_MESSAGE';

export function extractErrorMessage(error: unknown) {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object' && error !== null) {
    if (
      'message' in error &&
      typeof error.message === 'string' &&
      error.message.trim().length > 0
    ) {
      return error.message;
    }
    return stringify(error);
  }
  return MESSAGE_DEFAULT;
}
