import { useEffect, useMemo, useRef } from 'react';
import { GUEST_USER_ID, useAuth } from '../contexts/AuthContext';
import { getSessionId } from '../util/session/getSessionId';
import { updateSessionStatus } from '../util/session/updateSessionStatus';

export const POLLING_INTERVAL = 30000;

export const useSessionId = () => {
  const { userData } = useAuth();

  const userId = useMemo(() => {
    return userData?.id || GUEST_USER_ID;
  }, [userData?.id]);

  const sessionId = getSessionId();
  const prevSessionId = useRef<string>();
  const prevUserId = useRef<string>();
  const intervalId = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const trackSessionPresence = async () => {
      if (
        prevUserId.current === userId &&
        prevSessionId.current === sessionId
      ) {
        return;
      }

      await updateSessionStatus(userId, 'online', sessionId);
      prevUserId.current = userId;
      prevSessionId.current = sessionId;
    };

    const startPolling = () => {
      intervalId.current = setInterval(async () => {
        await updateSessionStatus(userId, 'online', sessionId);
      }, POLLING_INTERVAL);
    };

    trackSessionPresence();
    startPolling();

    return () => {
      const handler = async () => {
        clearInterval(intervalId.current);

        if (prevUserId.current === GUEST_USER_ID) {
          await updateSessionStatus(
            GUEST_USER_ID,
            'offline',
            prevSessionId.current,
          );
        }
      };
      handler();
    };
  }, [sessionId, userId]);

  return sessionId;
};
