/* eslint-disable jsx-a11y/alt-text */
import { memo } from '../util/memo';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { ImageOptimized, ImageOptimizedProps } from './image/ImageOptimized';

export type ImageOverlayedProps = ImageOptimizedProps & {
  children?: JSX.Element | JSX.Element[];
  sx?: SxProps;
};

function ImageOverlayedUnmemoized(props: ImageOverlayedProps) {
  const { children, sx, ...imgProps } = props;
  const { width, height } = imgProps;
  return (
    <Box
      sx={{
        bgColor: 'rgba(255,255,255,0.1)',
        borderRadius: 1.5,
        overflow: 'hidden',
        poition: 'relative',
        border: '1px solid rgba(255, 255, 255, 0.75)',
        height,
        width,
        ...sx,
      }}
    >
      {!!children && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            width,
            height,
            justifyContent: 'center',
            alignItems: 'flex-end',
            p: 2,
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      )}
      <ImageOptimized {...imgProps} />
    </Box>
  );
}
export const ImageOverlayed = memo(ImageOverlayedUnmemoized);
