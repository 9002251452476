import { GroupFilter } from '../../types/firestore/User/ChannelGroup';
import { sortedHash } from '../hash/sortedHash';

export function toChannelGroupPath(userId: string, groupFilter: GroupFilter) {
  const docId = toChannelGroupId(groupFilter);
  return `User/${userId}/ChannelGroup/${docId}`;
}

export const toChannelGroupId = (groupFilter: GroupFilter) => {
  return sortedHash(groupFilter[0]);
};
