import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { Typography } from '@mui/material';
import Stack from '@mui/system/Stack';
import { Link } from '../../Link';
import { truncateIfTooLong } from '../../../util/truncate';
import { useTheme } from '@mui/material/styles';
import { User } from '../../../../functions/src/types/firestore/User';
import { GradientTypography } from '../../gradients/GradientTypography';
import { AvatarGroupNext } from '../../AvatarGroupNext';

export type FriendListItemLayoutProps = Pick<
  User,
  'username' | 'imgUrl' | 'status'
> & {
  userId: string;
  message?: string;
  mutualFriendsCountEstimate?: number;
  children?: ReactNode;
};

const FriendListItemLayoutUnmemoized = ({
  children,
  ...props
}: FriendListItemLayoutProps) => {
  const theme = useTheme();
  const {
    username,
    imgUrl,
    userId,
    status,
    mutualFriendsCountEstimate,
    message,
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      minHeight="76px"
      spacing={2}
    >
      <Link style={{ textDecoration: 'none' }} href={`/profile/${userId}`}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          sx={{
            '.MuiBadge-badge.MuiBadge-dot': {
              height: '12px',
              width: '12px',
              borderRadius: '64px',
              border: '1px solid #FFFFFF',
              boxShadow: `0 0 0 3px ${theme.palette.background.elevation[2]}`,
            },
          }}
        >
          <AvatarGroupNext
            imgUrls={[imgUrl]}
            avatarProps={{ height: 56, width: 56 }}
            userId={userId}
            showStatus={true}
          />
          <Stack direction="column">
            <GradientTypography variant="subtitle1">
              {truncateIfTooLong(username)}
            </GradientTypography>
            {mutualFriendsCountEstimate !== undefined &&
              mutualFriendsCountEstimate > -1 && (
                <Typography
                  variant="body1"
                  color="primary.light"
                  sx={{ fontWeight: 'medium' }}
                >
                  {truncateIfTooLong(
                    `${
                      mutualFriendsCountEstimate >= 10
                        ? '9+'
                        : mutualFriendsCountEstimate
                    } Mutual Friend${
                      mutualFriendsCountEstimate === 1 ? '' : 's'
                    }`,
                    26,
                  )}
                </Typography>
              )}

            {status && (
              <Typography variant="subtitle2" color="text.secondary">
                {truncateIfTooLong(status, 24)}
              </Typography>
            )}
            {message && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ wordBreak: 'break-word' }}
              >
                {message}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Link>
      {children}
    </Stack>
  );
};

export const FriendListItemLayout = memo(FriendListItemLayoutUnmemoized);
