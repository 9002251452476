import { isSupport } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isSupport';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';

export const sortChannelPreviews = (
  channelPreviews: ChannelGroup<keyof GroupFilterMap, Date>[],
) => {
  return channelPreviews.sort((a, b) => {
    const isSupportA = isSupport(a);
    const isSupportB = isSupport(b);
    const isTemporaryA = a.permanence === 'temporary';
    const isTemporaryB = b.permanence === 'temporary';

    if (isTemporaryA && !isTemporaryB) {
      return -1;
    }
    if (!isTemporaryA && isTemporaryB) {
      return 1;
    }

    if (a.hasMultipleChannels && !b.hasMultipleChannels) {
      return -1;
    }
    if (!a.hasMultipleChannels && b.hasMultipleChannels) {
      return 1;
    }

    if (isSupportA && !isSupportB) {
      return -1;
    }
    if (!isSupportA && isSupportB) {
      return 1;
    }

    return b.lastUpdated.getTime() - a.lastUpdated.getTime();
  });
};
