import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from '../Link';

export const ACCORDION_TITLE_LIVESTREAM = 'GET YOUR STREAM NOTICED!';

export type AccordionBodyLivestreamProps = {
  tournamentTitle: string;
};

const AccordionBodyLivestreamUnmemoized = ({
  tournamentTitle,
}: AccordionBodyLivestreamProps) => {
  return (
    <Box>
      <Typography>
        By incorporating both &apos;BluMint&apos; and &quot;{tournamentTitle}
        &quot; in your stream&apos;s title or description, you increase the
        likelihood of your stream being discovered and featured by our video
        fetchers. We encourage you to include both for optimal visibility. If
        you have any further questions or need assistance, please don&apos;t
        hesitate to reach out to our support team. Happy streaming!
      </Typography>
      <Typography sx={{ pt: 2 }}>
        <Link
          href="https://www.youtube.com/t/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          {"YouTube's Terms of Service"}
        </Link>
      </Typography>
      <Typography>
        <Link
          href="https://www.twitch.tv/p/en/legal/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {"Twitch's Terms of Service"}
        </Link>
      </Typography>
    </Box>
  );
};

export const AccordionBodyLivestream = memo(AccordionBodyLivestreamUnmemoized);
