import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  GradientTypography,
  GradientTypographyProps,
} from '../gradients/GradientTypography';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientIcon } from '../gradients/GradientIcon';
import { useCurrency } from '../../contexts/CurrencyContext';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { usePrizeFormat } from '../../hooks/tournaments/usePrizeFormat';
import Box from '@mui/material/Box';
import SportsEsportsRoundedIcon from '@mui/icons-material/SportsEsportsRounded';
import { PayoutFormatted } from '../../../functions/src/types/firestore/Game/Competition';
import { formatPrizeAmount } from '../../util/tournaments/formatPrizeAmount';

export type PrizeSummary = {
  showIcons?: boolean;
  direction?: 'row' | 'column';
} & PayoutFormatted;

const VARIANT_TO_ICON_SIZE = {
  h4: { height: '35px', width: '35px' },
  h5: { height: '26px', width: '26px' },
  h6: { height: '20px', width: '20px' },
  default: { height: '18px', width: '18px' },
};

export type PayoutSummaryProps = PrizeSummary &
  Omit<GradientTypographyProps, 'gradientColor'>;

const PayoutSummaryUnmemoized = ({
  amountFiat,
  countIlliquid,
  countUnassured,
  showIcons = true,
  direction = 'row',
  sx,
  ...props
}: PayoutSummaryProps) => {
  const { currencyOptions } = useCurrency();
  const prizeFormatted = usePrizeFormat({
    amount: amountFiat || 0,
    currency: currencyOptions.currency.name,
  });
  const iconSize =
    props.variant && props.variant in VARIANT_TO_ICON_SIZE
      ? VARIANT_TO_ICON_SIZE[props.variant]
      : VARIANT_TO_ICON_SIZE.default;

  const prizeItems = useMemo(() => {
    return [
      {
        value: amountFiat,
        icon: EmojiEventsIcon,
        formatFunction: () => {
          return prizeFormatted;
        },
        color: 'success',
      },
      {
        value: countIlliquid,
        icon: CardGiftcardIcon,
        formatFunction: formatPrizeAmount,
        color: 'royal',
      },
      {
        value: countUnassured,
        icon: SportsEsportsRoundedIcon,
        formatFunction: formatPrizeAmount,
        color: 'warning',
        iconSx: { mb: -0.5 },
      },
    ];
  }, [amountFiat, countIlliquid, countUnassured, prizeFormatted]);

  return (
    <Stack
      direction={direction}
      alignItems="center"
      spacing={1}
      flexWrap={'wrap'}
      sx={{
        flexShrink: 0,
      }}
    >
      {prizeItems.map((item) => {
        const { value, color, icon, formatFunction, iconSx = {} } = item;
        return (
          !!value &&
          value !== 0 && (
            <Box
              key={`${value}-${color}-${icon}`}
              sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
            >
              <GradientTypography
                gradientColor={`${color}.vertical`}
                {...props}
                sx={{
                  fontWeight: 500,
                  ...sx,
                }}
              >
                {formatFunction(value)}
              </GradientTypography>
              {showIcons && (
                <GradientIcon
                  gradientColor={`${color}.horizontal`}
                  IconComponent={icon}
                  sx={{ ...iconSize, ...iconSx }}
                />
              )}
            </Box>
          )
        );
      })}
    </Stack>
  );
};

export const PayoutSummary = memo(PayoutSummaryUnmemoized);
