import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { useCallback } from 'react';
import { memo } from '../../util/memo';
import { LoadingIconButton } from '../buttons/LoadingIconButton';
import { GradientIcon } from '../gradients/GradientIcon';
import { ImageOptimized } from '../image/ImageOptimized';
import { ChannelGroupPermanence } from '../../../functions/src/types/firestore/User/ChannelGroup';

export type PermanenceButtonProps = {
  permanence: Omit<ChannelGroupPermanence, 'fixed'>;
  tournamentId: string;
};

const PermanenceButtonUnmemoized = ({
  tournamentId,
  permanence,
}: PermanenceButtonProps) => {
  const isTemporary = permanence === 'temporary';

  const togglePermanence = useCallback(async () => {
    const { setTournamentChannelGroupExternal } = await import(
      '../../firebaseCloud/messaging/setTournamentChannelGroupExternal'
    );
    await setTournamentChannelGroupExternal({
      tournamentId,
      permanence: isTemporary ? 'unpinnable' : 'temporary',
    });
  }, [isTemporary, tournamentId]);

  return (
    <Tooltip
      arrow
      title={'This is a temporary chat. Pin to your sidebar?'}
      disableHoverListener={!isTemporary}
    >
      <Box>
        <LoadingIconButton
          onClick={togglePermanence}
          sx={{
            height: '32px',
            width: '32px',
            p: '2px',
          }}
        >
          {isTemporary ? (
            <GradientIcon
              IconComponent={PushPinOutlinedIcon}
              sx={{
                transform: 'rotate(45deg)',
                height: '20px',
                width: '20px',
              }}
            />
          ) : (
            <ImageOptimized
              src="/assets/icons/unpin-primary-gradient.svg"
              height={28}
              width={28}
            />
          )}
        </LoadingIconButton>
      </Box>
    </Tooltip>
  );
};

export const PermanenceButton = memo(PermanenceButtonUnmemoized);
