export function capitalizeFirstLetter(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function toCamelCase(str: string): string {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => {
    return chr.toUpperCase();
  });
}

export function toTitleCase(
  str: string,
  options: { fromKebab: boolean } = { fromKebab: false },
): string {
  return str
    .split(options.fromKebab ? '-' : ' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export function toKebabCase(str?: string): string {
  return (
    str
      ?.toLowerCase()
      .split(' ')
      .join('-')
      .replace(':', '-')
      .replace('--', '-')
      .replace(`'`, '') || ''
  );
}
