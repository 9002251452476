import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Diversity3Rounded from '@mui/icons-material/Diversity3Rounded';
import Diversity1Rounded from '@mui/icons-material/Diversity1Rounded';
import { GradientIcon } from '../../gradients/GradientIcon';
import { truncateIfTooLong } from '../../../util/truncate';
import { memo } from '../../../util/memo';

export type NoFriendsProps = {
  variant: 'fam' | 'friends' | 'friend requests';
  isSelf: boolean;
  username?: string;
};

const NoFriendsUnmemoized = ({ variant, isSelf, username }: NoFriendsProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 1,
        }}
      >
        <GradientIcon
          IconComponent={
            variant === 'fam' ? Diversity1Rounded : Diversity3Rounded
          }
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 150,
            maxHeight: 150,
            p: 4,
          }}
        />
      </Box>
      <Box sx={{ width: '60%', textAlign: 'center' }}>
        <Typography variant="h6">{`${
          isSelf || !username
            ? `No`
            : `${truncateIfTooLong(username)} doesn't have any`
        } ${variant}!`}</Typography>
        <Typography variant="body1">
          {variant === 'friends'
            ? isSelf
              ? 'Add some friends above.'
              : 'Add as a friend?'
            : variant === 'fam'
            ? isSelf
              ? 'Move your friends to fam in the edit tab.'
              : 'Add as a friend?'
            : isSelf
            ? 'You can send friend requests instead.'
            : 'Add some friends above.'}
        </Typography>
      </Box>
    </Box>
  );
};

export const NoFriends = memo(NoFriendsUnmemoized);
