import { Stack, Typography } from '@mui/material';
import { LottieLoader } from 'src/components/LottieLoader';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { useCallback, useEffect } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from 'src/contexts/AuthContext';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { useWizard } from 'src/components/wizard/Wizard';
import { ResendEmailButton } from 'src/components/buttons/ResendEmailButton';
import { useSignOut } from '../../../hooks/auth/useSignOut';

const AuthenticationVerifyingEmailDialogUnmemoized: React.FC = () => {
  const { user } = useAuth();
  const { go, reset } = useWizard();

  const { signOut } = useSignOut();
  const resetAuth = useCallback(async () => {
    await signOut();
    reset();
    go('Sign In Options');
  }, [go, reset, signOut]);

  useEffect(() => {
    if (user?.emailVerified) {
      go('Verifying Email Success');
    }
  }, [user?.emailVerified, go]);

  return (
    <DialogBodyStandard
      title={'Verify Your Email'}
      description={
        <Stack alignItems="center" spacing={6}>
          <LottieLoader sx={{ height: '75px', width: '75px' }} />
          <Typography variant="h6" textAlign="center">
            Please click the link in the email <br /> we have just sent to{' '}
            {user?.email}.
          </Typography>
        </Stack>
      }
    >
      <Stack direction="row" spacing={4} width="100%" alignSelf="center">
        <ResendEmailButton />
        <LoadingButton
          variant="contained"
          color="error"
          size="large"
          onClick={resetAuth}
          sx={{ flex: 1 }}
        >
          Cancel
        </LoadingButton>
      </Stack>
    </DialogBodyStandard>
  );
};

export const AuthenticationVerifyingEmailDialog = memo(
  AuthenticationVerifyingEmailDialogUnmemoized,
);
