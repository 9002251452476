import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { GradientIcon } from '../gradients/GradientIcon';
import { memo } from '../../util/memo';

export type DialogBackButtonProps = {
  goBack: () => void;
};

export const DialogBackButton = memo(function DialogBackButtonUnmemoized({
  goBack,
}: DialogBackButtonProps) {
  if (!goBack) return null;

  return (
    <IconButton onClick={goBack} edge={'start'}>
      <GradientIcon
        IconComponent={ArrowBackIcon}
        gradientColor={'primary.horizontal'}
      />
    </IconButton>
  );
});
