import Box from '@mui/material/Box';
import { AdSizeAdjustable } from '../../../functions/src/types/firestore/User/AdBacklog';
import { memo } from '../../util/memo';
import { AdapexAdContainer, AdapexAdContainerProps } from './AdapexAdContainer';

export type AdapexAdDynamicProps = Omit<
  AdapexAdContainerProps,
  'width' | 'height'
> & {
  dimensionsMobile: AdSizeAdjustable;
  dimensionsDesktop: AdSizeAdjustable;
};

const AdapexAdDynamicUnmemoized = (props: AdapexAdDynamicProps) => {
  const { dimensionsMobile, dimensionsDesktop, ...rest } = props;
  return (
    <>
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <AdapexAdContainer
          width={dimensionsDesktop.width}
          height={dimensionsDesktop.height}
          adjustable={dimensionsDesktop.adjustable}
          {...rest}
        />
      </Box>
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <AdapexAdContainer
          width={dimensionsMobile.width}
          height={dimensionsMobile.height}
          adjustable={dimensionsMobile.adjustable}
          {...rest}
        />
      </Box>
    </>
  );
};

export const AdapexAdDynamic = memo(AdapexAdDynamicUnmemoized);
