import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import { useEffect, useMemo, useState } from 'react';
import { memo } from '../util/memo';
import { ClipboardShare } from './ClipboardShare';
import { GradientIconButton } from './gradients/GradientIconButton';
import { SxProps } from '@mui/material/styles';
import { useAuth } from 'src/contexts/AuthContext';
import { useGuardSignIn } from 'src/hooks/useGuardSignIn';

export type ShareLikedCountProps = {
  likesOriginal: number;
  path?: string;
  shareUrl?: string;
  shareButtonSx?: SxProps;
  likeButtonSx?: SxProps;
  likeCountProps?: TypographyProps;
  guardTitle?: string;
};

export const ShareLikedCount = memo(function ShareLikedCountUnmemoized({
  shareUrl,
  shareButtonSx,
  likeButtonSx,
  likeCountProps,
  path,
  likesOriginal,
  guardTitle = 'Like Something?',
}: ShareLikedCountProps) {
  const [isLockedToggleLike, setIsLockedToggleLike] = useState(false);
  const [count, setCount] = useState(likesOriginal);
  useEffect(() => {
    if (count !== likesOriginal) {
      setCount(likesOriginal);
    }
    // PURPOSELY IGNORING
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likesOriginal]);
  const { userData } = useAuth();
  const { guardSignIn } = useGuardSignIn();
  const liked = useMemo(() => {
    return !!path && userData?.likedPaths.includes(path);
  }, [path, userData?.likedPaths]);
  const [likedOnClick, setLikedOnClick] = useState(liked);
  useEffect(() => {
    if (liked !== likedOnClick) {
      setLikedOnClick(liked);
    }
    // PURPOSELY IGNORING
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liked]);
  const toggleLike = guardSignIn(async () => {
    if (isLockedToggleLike || !path) {
      return;
    }
    const { handleLike } = await import('../firebaseCloud');
    setIsLockedToggleLike(true);
    try {
      setLikedOnClick((prev) => {
        return !prev;
      });
      setCount(liked ? count - 1 : count + 1);
      await handleLike({
        doIncrement: !likedOnClick,
        path: path,
      });
    } finally {
      setIsLockedToggleLike(false);
    }
  }, guardTitle);

  return (
    <>
      <Box
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {shareUrl && (
          <ClipboardShare value={shareUrl}>
            <GradientIconButton
              gradientColor="primary.horizontal"
              IconComponent={ShareIcon}
              sx={shareButtonSx}
            />
          </ClipboardShare>
        )}
        {!!toggleLike && (
          <GradientIconButton
            onClick={toggleLike!}
            gradientColor="primary.horizontal"
            IconComponent={
              !path || likedOnClick ? FavoriteIcon : FavoriteBorderOutlinedIcon
            }
            sx={likeButtonSx}
          />
        )}
        <Box sx={{ ml: '-4px' }}>
          <Typography color="text.primary" variant="h6" {...likeCountProps}>
            {count}
          </Typography>
        </Box>
        {/* <Typography
              sx={sx[`${parent}`]}
              variant={
                parent === 'itemCard'
                  ? 'body2'
                  : parent === 'searchResultCard'
                  ? 'caption'
                  : 'h6'
              }
            >
              {count}
            </Typography> */}
      </Box>
    </>
  );
});
