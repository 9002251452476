import { DialogFriendRequest } from '../../components/profile/header/DialogFriendRequest';
import { useDialog } from '../useDialog';
import { ChipUser } from '../../components/ChipUser';
import { useAuth } from '../../contexts/AuthContext';

export const FRIEND_REQUEST_DIALOG_ID = 'FRIEND_REQUEST_DIALOG';

export type UseFriendRequestDialogParams = {
  username: string;
  imgUrl: string;
  userId: string;
};

export const useFriendRequestDialog = ({
  username,
  imgUrl,
  userId,
}: UseFriendRequestDialogParams) => {
  const { uid } = useAuth();
  const { open: openDialog, close } = useDialog(FRIEND_REQUEST_DIALOG_ID);

  const sendRequest = async (message: string) => {
    if (!uid) {
      return;
    }
    const { sendFriendRequest } = await import('../../firebaseCloud');
    await sendFriendRequest({ fromId: uid, toId: userId, message });
    close();
  };
  const open = () => {
    openDialog({
      title: 'Send Friend Request',
      showCloseIcon: true,
      children: (
        <DialogFriendRequest
          UserChip={
            <ChipUser
              username={username}
              avatarUrl={imgUrl}
              href={`/profile/${userId}`}
              sx={{ mx: 1 }}
            />
          }
          onSubmit={sendRequest}
        />
      ),
    });
  };
  return { open, close };
};
