import { Fragment, cloneElement } from 'react';
import { memo } from '../util/memo';
import { SxProps } from '@mui/material/styles';
import { insertInnermostChild } from '../util/insertInnermostChild';

export type OptionalWrapperProps = {
  doWrap: boolean;
  Wrapper: React.ReactElement<{ sx?: SxProps; children?: React.ReactNode }>;
  children: React.ReactNode;
  sx?: SxProps;
};

export const OptionalWrapper = memo(function OptionalWrapperUnmemoized({
  doWrap,
  Wrapper,
  children,
  sx = {},
}: OptionalWrapperProps) {
  return doWrap ? (
    insertInnermostChild(cloneElement(Wrapper, { sx }), children)
  ) : (
    <Fragment>{children}</Fragment>
  );
});
