import { AdSize, AdUnitMap, AspectRatio } from './AspectRatio';
import { toFraction } from './toFraction';

export function findClosest(
  adUnitMap: AdUnitMap,
  width: number,
  height: number,
  adjustable?: 'width' | 'height',
) {
  const targetAspectRatio = toFraction(`${width}x${height}`);

  const { aspectRatio, adUnitId } = (
    Object.entries(adUnitMap) as [AspectRatio, string][]
  )
    .map(([aspectRatioCandidate, adUnitIdCandidate]) => {
      return {
        aspectRatio: aspectRatioCandidate,
        adUnitId: adUnitIdCandidate,
        difference: Math.abs(
          toFraction(aspectRatioCandidate) - targetAspectRatio,
        ),
      };
    })
    .reduce((candidate, current) => {
      return candidate.difference < current.difference ? candidate : current;
    });

  let adjustedWidth = width;
  let adjustedHeight = height;

  if (adjustable === 'height') {
    const [adWidth, adHeight] = aspectRatio.split('x').map(Number);
    adjustedHeight = Math.round(width * (adHeight / adWidth));
  } else if (adjustable === 'width') {
    const [adWidth, adHeight] = aspectRatio.split('x').map(Number);
    adjustedWidth = Math.round(height * (adWidth / adHeight));
  }

  return {
    width: adjustedWidth,
    height: adjustedHeight,
    adUnitId,
    aspectRatio,
  } as AdSize;
}
