import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/system/Stack';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../contexts/AuthContext';
import { LoadingIconButton } from '../../buttons/LoadingIconButton';
import { GradientBorder } from '../../gradients/GradientBorder';
import { useIndexingHits } from '../../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { FriendListItemLayout } from './FriendListItemLayout';
import { GradientIcon } from '../../gradients/GradientIcon';

export type FriendRequestCardProps = FriendHit;

const FriendCardRequestUnmemoized = (hit: FriendRequestCardProps) => {
  const { uid } = useAuth();
  const { remove, add } = useIndexingHits();
  const { objectID, sender, receiver, mutualFriendsCountEstimate = 0 } = hit;
  const friendToRender = sender.userId === uid ? receiver : sender;
  const { username, imgUrl, userId, friendRequest } = friendToRender;
  const message = friendRequest?.message;

  const acceptRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    const { acceptFriendRequest } = await import('../../../firebaseCloud');
    await acceptFriendRequest({ fromId: uid, toId: userId });
    const friendLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid),
    );
    const friendRequestLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid),
    );
    remove(friendRequestLayout, objectID);
    add(friendLayout, hit as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit, objectID, uid, userId]);

  const rejectRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    const { denyFriendRequest } = await import('../../../firebaseCloud');
    await denyFriendRequest({ fromId: uid, toId: userId });
    const friendRequestLayout = convertToHash(
      fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid),
    );
    remove(friendRequestLayout, objectID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectID, uid, userId]);

  return (
    <FriendListItemLayout
      {...{ username, imgUrl, userId, message, mutualFriendsCountEstimate }}
    >
      <Stack direction="row" spacing={2} sx={{ pr: 2 }} height="36px">
        <LoadingIconButton
          onClick={async () => {
            await acceptRequest();
          }}
          color="secondary"
          sx={{
            p: 0,
          }}
        >
          <GradientBorder
            gradientColor="secondary.vertical"
            borderRadius="36px"
            disableGlow
            sx={{
              height: '100%',
              width: '36px',
              '.MuiSvgIcon-root': {
                mt: '4px',
              },
            }}
          >
            <GradientIcon
              gradientColor="secondary.vertical"
              IconComponent={CheckIcon}
              sx={{ height: '24px', width: '24px' }}
            />
          </GradientBorder>
        </LoadingIconButton>
        <LoadingIconButton
          onClick={async () => {
            await rejectRequest();
          }}
          color="error"
          sx={{ p: 0 }}
        >
          <GradientBorder
            gradientColor="error.vertical"
            borderRadius="36px"
            disableGlow
            sx={{
              height: '100%',
              width: '36px',
              '.MuiSvgIcon-root': {
                mt: '4px',
              },
            }}
          >
            <GradientIcon
              gradientColor="error.vertical"
              IconComponent={CloseIcon}
              sx={{ height: '24px', width: '24px' }}
            />
          </GradientBorder>
        </LoadingIconButton>
      </Stack>
    </FriendListItemLayout>
  );
};

export const FriendCardRequest = memo(FriendCardRequestUnmemoized);
