import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

export type useIntersectionObserverProps = {
  rootMargin?: string;
  threshold?: number | number[];
  onIntersect: () => void;
};

export const useIntersectionObserver = ({
  rootMargin = '0px',
  threshold = 1.0,
  onIntersect,
}: useIntersectionObserverProps) => {
  const { ref, inView } = useInView({
    rootMargin,
    threshold,
  });

  useEffect(() => {
    if (inView) {
      onIntersect();
    }
  }, [inView, onIntersect]);

  return { ref };
};
