import { AdAspectRatio } from '../../types/firestore/AdCampaign';
import { AdSizeAdjustable } from '../../types/firestore/User/AdBacklog';

export const GAME_CARD_DIMENSIONS = {
  width: 538,
  height: 360,
  adjustable: 'width',
} as const;

export const TOURNAMENT_CARD_DIMENSIONS = {
  width: 288,
  height: 190,
  adjustable: 'height',
} as const;

export const FRIEND_DIMENSIONS = {
  width: 440,
  height: 118,
  adjustable: 'height',
} as const;

export const SIDE_DRAWER_DESKTOP = {
  width: 476,
  height: 118,
  adjustable: 'height',
} as const;

export const MESSAGING_DIMENSIONS = {
  width: 474,
  height: 127,
  adjustable: 'height',
} as const;

export const MESSAGING_DIMENSIONS_MOBILE = {
  width: 600,
  height: 60,
  adjustable: 'height',
} as const;

export const PARTICIPANT_DIMENSIONS = {
  width: 608,
  height: 56,
  adjustable: 'height',
} as const;

export const PARTICIPANT_DIMENSIONS_MOBILE = {
  width: 490,
  height: 56,
  adjustable: 'height',
} as const;

export const REGISTRATION_DIMENSIONS = {
  width: 800,
  height: 83,
  adjustable: 'height',
} as const;

export const REGISTRATION_DIMENSIONS_MOBILE = {
  width: 562,
  height: 160,
  adjustable: 'height',
} as const;

export const BRACKET_DIMENSIONS = {
  width: 368,
  height: 98,
  adjustable: 'height',
} as const;

export const LEADERBOARD_DIMENSIONS_MOBILE = {
  width: 554,
  height: 98,
  adjustable: 'height',
} as const;

export const OVERVIEW_PAGE_DIMENSIONS = {
  width: 600,
  height: 160,
  adjustable: 'height',
} as const;

export const OVERVIEW_PAGE_DIMENSIONS_MOBILE = {
  width: 562,
  height: 166,
  adjustable: 'height',
} as const;

export const PROFILE_PAGE_DIMENSIONS = {
  width: 600,
  height: 160,
  adjustable: 'height',
} as const;

export const SIDE_DRAWER_MOBILE = {
  width: 600,
  height: 160,
  adjustable: 'height',
} as const;

export const TEAM_MEMBERS_DIMENSIONS = {
  width: 600,
  height: 80,
  adjustable: 'height',
} as const;

export const USER_REGISTRATION_DIMENSIONS = {
  width: 600,
  height: 80,
  adjustable: 'height',
} as const;

export const CHECKIN_DIALOG_DIMENSIONS = {
  width: 400,
  height: 100,
  adjustable: 'height',
} as const;

export const AD_DIMENSIONS: Record<AdAspectRatio, Array<AdSizeAdjustable>> = {
  '16:9': [],
  '4:3': [],
  '8:1': [],
  '6:5': [],
  '6:1': [],
  '4:15': [],
  '1:1': [],
  '3:2': [GAME_CARD_DIMENSIONS, TOURNAMENT_CARD_DIMENSIONS],
};
