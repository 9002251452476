import { memo } from '../util/memo';
import { LottieLoader, LottieLoaderProps } from './LottieLoader';
import { ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import { OptionalWrapper } from './OptionalWrapper';

export type LoadingWrapperProps = LottieLoaderProps & {
  wrapperSx?: SxProps;
  sx?: SxProps;
  isLoading: boolean;
  children: ReactNode;
};

const LoadingWrapperUnmemoized = ({
  isLoading,
  children,
  wrapperSx,
  sx,
}: LoadingWrapperProps) => {
  return (
    <OptionalWrapper
      sx={wrapperSx}
      doWrap={isLoading}
      Wrapper={<LottieLoader sx={sx} />}
    >
      {children}
    </OptionalWrapper>
  );
};

export const LoadingWrapper = memo(LoadingWrapperUnmemoized);
