import { useState } from 'react';
import { memo } from '../../util/memo';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GradientIcon } from '../gradients/GradientIcon';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useAuth } from '../../contexts/AuthContext';
import { LottieLoader } from '../LottieLoader';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export type ChatMenuProps = Omit<MenuProps, 'open'> & {
  createChat: () => Promise<void> | void;
  onCreateSupport?: () => Promise<void> | void;
};

const ChatMenuUnmemoized = ({
  anchorEl,
  createChat,
  onCreateSupport,
  ...otherProps
}: ChatMenuProps) => {
  const { uid } = useAuth();

  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const menuItems: {
    onClick: () => Promise<void> | void;
    label: string;
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, never>, 'svg'>>;
  }[] = [
    {
      onClick: async () => {
        await createChat();
      },
      label: 'Create Chat',
      Icon: AddIcon,
    },
    {
      onClick: async () => {
        if (!uid) {
          return;
        }
        setIsLoading(true);

        const { data: channelGroup } = await setPersonalChannelGroup({
          type: 'support',
          friendIds: [],
          messagesInitial: [
            {
              text: 'Welcome to BluMint Support. Drop your message below and a member of our team will get back to you shortly :)',
            },
          ],
        });
        openChannelGroup(channelGroup.id);
        await onCreateSupport?.();
        setIsLoading(false);
      },
      label: 'Create Support Chat',
      Icon: HelpOutlineIcon,
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => {
        onCreateSupport?.();
      }}
      {...otherProps}
    >
      {menuItems.map(({ onClick, label, Icon }) => {
        return (
          <MenuItem
            onClick={onClick}
            sx={{ maxWidth: '230px', p: 0 }}
            key={label}
          >
            <Stack
              direction="row"
              width="100%"
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
              sx={{ p: 2 }}
            >
              {isLoading && label === 'Create Support Chat' ? (
                <LottieLoader sx={{ width: '20px', height: '20px' }} />
              ) : (
                <GradientIcon
                  gradientColor="primary.horizontal"
                  IconComponent={Icon}
                  sx={{ height: '24px', width: '24px' }}
                />
              )}
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ pr: 3, textTransform: 'capitalize' }}
              >
                {label}
              </Typography>
            </Stack>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export const ChatMenu = memo(ChatMenuUnmemoized);
