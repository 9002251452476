import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useTheme } from '@mui/material/styles';
import { Link } from '../../Link';
import { OptionalWrapper } from '../../OptionalWrapper';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { PermanenceButton } from '../PermanenceButton';
import { ChannelGroup } from '../../../../functions/src/types/firestore/User/ChannelGroup';

export type ChannelGroupHeaderProps = Pick<
  ChannelGroup,
  'permanence' | 'title' | 'subtitle'
> & {
  gradientColor?: string;
  children: ReactNode;
  Figure: ReactNode;
  href?: string;
  tournamentId?: string;
};

const ChannelGroupHeaderUnmemoized = ({
  title,
  subtitle,
  children,
  Figure,
  href,
  tournamentId,
  permanence,
  gradientColor = 'primary.vertical',
}: ChannelGroupHeaderProps) => {
  const theme = useTheme();
  const { closeChannelGroup } = useActiveChannelGroup();
  const sxEllipsis = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };

  return (
    <Stack direction="column" height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: theme.palette.background.elevationSolid[3],
          borderBottom: {
            xs: 'none',
            lg: `1px solid ${theme.palette.primary.dark}`,
          },
          pl: 4,
          pr: 2,
        }}
      >
        <OptionalWrapper
          doWrap={!!href}
          Wrapper={
            <Link href={href!} style={{ textDecoration: 'none' }}></Link>
          }
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            width="100%"
            justifyContent="flex-start"
            minHeight={{ xs: '74px', lg: '59px' }}
          >
            {Figure}
            <Stack
              direction="column"
              sx={{ maxWidth: { xs: '444px', lg: '304px' } }}
            >
              <GradientTypography
                variant="h6"
                gradientColor={gradientColor}
                textTransform="uppercase"
                sx={sxEllipsis}
              >
                {title}
              </GradientTypography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={sxEllipsis}
              >
                {subtitle}
              </Typography>
            </Stack>
          </Stack>
        </OptionalWrapper>
        <Stack direction="row" alignItems="center">
          {!!tournamentId && permanence !== 'fixed' && (
            <PermanenceButton
              permanence={permanence}
              tournamentId={tournamentId}
            />
          )}
          <GradientIconButton
            IconComponent={CloseIcon}
            onClick={closeChannelGroup}
            sxOuter={{ height: '28px', width: '28px', alignSelf: 'center' }}
            sx={{ height: '24px', width: '24px' }}
          />
        </Stack>
      </Stack>

      {children}
    </Stack>
  );
};

export const ChannelGroupHeader = memo(ChannelGroupHeaderUnmemoized);
