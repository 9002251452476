import { TypographyOptions } from '@mui/material/styles/createTypography';
import { PALETTE } from './palette';

const TEXT_DECORATION = {
  textUnderlineOffset: '2px',
  textDecorationThickness: '1px',
};

export const TYPOGRAPHY: TypographyOptions = {
  htmlFontSize: 14,
  fontFamily: 'Barlow',
  h1: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '96px',
    lineHeight: 1.167,
    letterSpacing: '-1.5px',
  },
  h2: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '60px',
    lineHeight: 1.2,
    letterSpacing: '-0.5px',
  },
  h3: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: 1.167,
  },
  h4: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: 1.235,
    letterSpacing: '0.25px',
  },
  h5: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: 1.334,
  },
  h6: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: 1.6,
    letterSpacing: '0.15px',
  },
  body1: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.15px',
  },
  body2: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.43,
    letterSpacing: '0.17px',
  },
  subtitle1: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 1.75,
    letterSpacing: '0.15px',
  },
  subtitle2: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1.57,
    letterSpacing: '0.1px',
  },
  overline: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 2.66,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  caption: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: 1.66,
    letterSpacing: '0.4px',
  },
  button: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: 1.24,
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
  },
  buttonLarge: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.primary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  buttonLargeSecondary: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.secondary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  buttonMedium: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.primary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  buttonMediumSecondary: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.secondary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  buttonSmall: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.primary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  buttonSmallSecondary: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.56px',
    textTransform: 'uppercase',
    background: PALETTE.secondary.horizontal,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
  },
  common: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: PALETTE.rarity.common,
  },
  standard: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: PALETTE.rarity.standard,
  },
  rare: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: PALETTE.rarity.rare,
  },
  epic: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: PALETTE.rarity.epic,
  },
  legendary: {
    ...TEXT_DECORATION,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
    color: PALETTE.rarity.legendary,
  },
  inputLabel: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12px',
    letterSpacing: ' 0.15px',
    color: 'white',
  },
  helper: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.4px',
    color: 'rgba(204, 226, 255, 0.7)',
  },
};
