import Stack from '@mui/material/Stack';
import { GradientTypography } from '../gradients/GradientTypography';
import { DialogBackButton } from './DialogBackButton';
import { DialogCloseButton } from './DialogCloseButton';
import { memo } from '../../util/memo';

export type DialogNavbarProps = {
  onClose: () => void;
  goBack?: () => void;
  prevDialogId?: string;
};

export const DialogNavbar = memo(function DialogNavbarUnmemoized({
  goBack,
  onClose,
  prevDialogId,
}: DialogNavbarProps) {
  return (
    <Stack
      direction="row"
      justifyContent={!!goBack ? 'space-between' : 'flex-end'}
      mb={4}
    >
      {!!goBack && (
        <Stack direction="row" alignItems="center">
          <DialogBackButton goBack={goBack} />
          {!!prevDialogId && (
            <GradientTypography
              variant="h6"
              gradientColor="primary.vertical"
              textTransform="uppercase"
            >
              Back To {prevDialogId}
            </GradientTypography>
          )}
        </Stack>
      )}
      <DialogCloseButton onClose={onClose} />
    </Stack>
  );
});
