import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { AvatarNext, AvatarNextProps } from './AvatarNext';
import { SxProps } from '@mui/material/styles';
import { useOnlineStatus } from '../hooks/useOnlineStatus';
import { Status } from './Status';
import { OptionalWrapper } from './OptionalWrapper';
import { memo } from '../util/memo';

export type AvatarGroupNextProps = AvatarGroupProps & {
  imgUrls: string[];
  borderColor?: string;
  avatarProps?: AvatarNextProps;
  avatarGroupProps?: AvatarNextProps;
  avatarSx?: SxProps;
  badgeSx?: SxProps;
  userId?: string;
  showStatus?: boolean;
};

const AvatarGroupNextUnmemoized = (props: AvatarGroupNextProps) => {
  const {
    imgUrls,
    borderColor,
    userId,
    showStatus = false,
    avatarProps = { height: 56, width: 56 },
    avatarGroupProps = { height: 42, width: 42 },
    badgeSx = {},
    avatarSx = {},
    ...rest
  } = props;

  const { onlineStatus } = useOnlineStatus([userId || '']);
  const onlineStatusEnum = onlineStatus[String(userId)] ? 'online' : 'offline';

  return (
    <>
      {imgUrls.length > 1 ? (
        <AvatarGroup {...rest}>
          {imgUrls.map((imgUrl) => {
            return (
              <AvatarNext
                key={imgUrl}
                src={imgUrl}
                {...avatarGroupProps}
                sx={{ ...avatarSx }}
              />
            );
          })}
        </AvatarGroup>
      ) : (
        <OptionalWrapper
          Wrapper={<Status kind="Badge" statusEnum={onlineStatusEnum} />}
          doWrap={showStatus}
          sx={badgeSx}
        >
          <AvatarNext
            key={imgUrls[0]}
            src={imgUrls[0]}
            {...avatarProps}
            sx={{
              border: !!borderColor ? `2px solid ${borderColor}` : 'none',
              ...avatarSx,
            }}
          />
        </OptionalWrapper>
      )}
    </>
  );
};
export const AvatarGroupNext = memo(AvatarGroupNextUnmemoized);
