import { memo } from '../../util/memo';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import { SignInMenuItem } from './SignInMenuItem';
import { useSignInItems } from '../../hooks/useSignInItems';

export type SignInMenuProps = {
  open: boolean;
  anchorEl: Element;
  onMenuClose: () => void;
};

export const SignInMenu = memo(function SignInMenuUnmemoized({
  open,
  anchorEl,
  onMenuClose,
}: SignInMenuProps) {
  const menuItems = useSignInItems(onMenuClose);
  const theme = useTheme();

  return (
    <ClickAwayListener onClickAway={onMenuClose}>
      <Menu
        open={open}
        anchorEl={anchorEl}
        elevation={8}
        hideBackdrop={true}
        sx={{
          width: '250px',
          '&.MuiPopover-root': {
            position: 'unset',
          },
          '&.MuiMenu-root .MuiPaper-root': {
            zIndex: `${theme.zIndex.menu} !important`,
          },
          '& .MuiList-root': {
            pt: 0,
            pb: 0,
          },
        }}
      >
        {menuItems.map((item, index) => {
          const { label, IconComponent } = item;
          return (
            <SignInMenuItem
              key={`${label}-${IconComponent?.name}`}
              {...item}
              index={index}
            />
          );
        })}
      </Menu>
    </ClickAwayListener>
  );
});
