export const PLACEHOLDER_REGEX = /\[PLACEHOLDER\]/g;
export const PLACEHOLDER_TEXT = '[PLACEHOLDER]';

export const REALTIME_CONFIGURATION_TEMPLATES = {
  friend:
    `_tags: Friendship/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND sender.friendRequest.status: accepted AND hasOtherAsFam.${PLACEHOLDER_TEXT}: false`,
  fam:
    `_tags: Friendship/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND sender.friendRequest.status: accepted AND hasOtherAsFam.${PLACEHOLDER_TEXT}: true`,
  friendRequest:
    `_tags: Friendship/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND receiver.userId: ${PLACEHOLDER_TEXT} AND receiver.friendRequest.status: received`,
} as const;
export type RealtimeConfigurationKey =
  keyof typeof REALTIME_CONFIGURATION_TEMPLATES;
