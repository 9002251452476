import React, { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { LoadingIconButton } from '../../buttons/LoadingIconButton';
import MessageIcon from '@mui/icons-material/Message';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { FriendListItemLayout } from './FriendListItemLayout';
import { usePersonalChannelGroup } from '../../../hooks/messaging/usePersonalChannelGroup';
import { GradientIcon } from '../../gradients/GradientIcon';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';

export type FriendCardProps = FriendHit;

const FriendCardUnmemoized = (hit: FriendCardProps) => {
  const { uid } = useAuth();
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const { sender, receiver } = hit;
  const friendToRender = sender.userId === uid ? receiver : sender;
  const { username, imgUrl, userId, status } = friendToRender;

  const openDm = useCallback(async () => {
    const { data: channelGroup } = await setPersonalChannelGroup({
      type: 'dm',
      friendIds: [userId],
    });

    openChannelGroup(channelGroup.id);
  }, [openChannelGroup, setPersonalChannelGroup, userId]);

  return (
    <FriendListItemLayout {...{ username, imgUrl, userId, status }}>
      <LoadingIconButton onClick={openDm}>
        <GradientIcon IconComponent={MessageIcon} />
      </LoadingIconButton>
    </FriendListItemLayout>
  );
};

export const FriendCard = memo(FriendCardUnmemoized);
