import { useCallback } from 'react';
import { useAlertDialog } from '../useAlertDialog';
import { getInviteErrorMessage } from '../../util/tournaments/getInviteErrorMessage';
import { InviteTeamMemberParams } from '../../firebaseCloud/tournament/inviteTeamMember';

export const INVITE_TEAM_MEMBER_ERROR = 'INVITE_TEAM_MEMBER_ERROR';

export type UseInviteGuardedProps = {
  onSuccess?: () => Promise<void> | void;
  onError?: (inviteError: {
    title: string;
    message: string;
  }) => Promise<void> | void;
};

export const useInviteGuarded = ({
  onSuccess,
  onError,
}: UseInviteGuardedProps) => {
  const { open: openAlertDialog } = useAlertDialog(INVITE_TEAM_MEMBER_ERROR);

  const inviteGuarded = useCallback(
    async (params: InviteTeamMemberParams) => {
      try {
        const { inviteTeamMember } = await import(
          '../../firebaseCloud/tournament/inviteTeamMember'
        );
        await inviteTeamMember(params);
        await onSuccess?.();
      } catch (error) {
        const { title, message } = getInviteErrorMessage(error);
        openAlertDialog({ title, description: message });
        await onError?.({ title, message });
      }
    },
    [onError, onSuccess, openAlertDialog],
  );

  return inviteGuarded;
};
