import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import { FriendCarouselHeader } from './FriendCarouselHeader';
import {
  FriendVerticalCarousel,
  useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { FriendCardEdit } from '../../cards/friend/FriendCardEdit';
import { NoFriends } from '../../profile/header/NoFriends';
import { useAuth } from '../../../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import {
  SIDE_DRAWER_DESKTOP,
  SIDE_DRAWER_MOBILE,
} from '../../../../functions/src/config/ads/adDimensions';
import { useMobile } from '../../../hooks/useMobile';
import { AdapexAdDynamic } from '../../ads/AdapexAdDynamic';
import {
  FRIENDS_EDIT_AD_DESKTOP,
  FRIENDS_EDIT_AD_1,
} from '../../../../functions/src/util/ads/adIds';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from './FriendsAdd';
import { Omit } from 'utility-types';

export type FriendsEditProps = {
  onlineFamCount: number;
  onlineFriendsCount: number;
};

const FRIENDS_HITS_PER_PAGE = 20 as const;

const FriendsEditUnmemoized: FC<FriendsEditProps> = ({
  onlineFamCount,
  onlineFriendsCount,
}) => {
  const { uid } = useAuth();
  const theme = useTheme();

  const friendFilter = !!uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid)
    : '';
  const friendConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendFilter,
    };
  }, [friendFilter]);

  const famFilter = !!uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['fam'], uid)
    : '';
  const famConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: famFilter,
    };
  }, [famFilter]);

  const isMobile = useMobile();

  const FriendVerticalCarouselAds = useFriendVerticalCarouselAds(
    FRIENDS_EDIT_AD_DESKTOP,
  );

  const CarouselComponent = useMemo(() => {
    return isMobile ? FriendVerticalCarousel : FriendVerticalCarouselAds;
  }, [FriendVerticalCarouselAds, isMobile]);

  const FamEditCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof CarouselComponent>,
        | 'RenderFriendHit'
        | 'Header'
        | 'NoFriends'
        | 'showDivider'
        | 'containerSx'
      >,
    ) => {
      return (
        <CarouselComponent
          RenderFriendHit={(friendCardProps) => {
            return <FriendCardEdit {...friendCardProps} isFam={true} />;
          }}
          {...props}
          Header={
            <FriendCarouselHeader title="FAM" onlineCount={onlineFamCount} />
          }
          NoFriends={<NoFriends variant="fam" isSelf={true} />}
          showDivider={false}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFamCount, CarouselComponent],
  );

  const FriendsEditCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof CarouselComponent>,
        | 'RenderFriendHit'
        | 'Header'
        | 'NoFriends'
        | 'showDivider'
        | 'containerSx'
      >,
    ) => {
      return (
        <CarouselComponent
          RenderFriendHit={FriendCardEdit}
          {...props}
          Header={
            <FriendCarouselHeader
              title="FRIENDS"
              onlineCount={onlineFriendsCount}
            />
          }
          NoFriends={<NoFriends variant="friends" isSelf={true} />}
          showDivider={false}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFriendsCount, CarouselComponent],
  );

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          lg: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 4,
      }}
    >
      <Stack
        spacing={6}
        justifyContent={'space-between'}
        sx={{
          px: 4,
          height: {
            xs: `calc(100% - ${SIDE_DRAWER_MOBILE.height}px)`,
            lg: `calc(100% - ${SIDE_DRAWER_DESKTOP.height}px)`,
          },
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FamEditCatalogWrapper}
            configureOptions={famConfigureOptions}
            index="CONTENT"
          />
        </Box>
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FriendsEditCatalogWrapper}
            configureOptions={friendConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
      <Box position="absolute" sx={{ bottom: 0 }}>
        <AdapexAdDynamic
          baseContainerId={FRIENDS_EDIT_AD_1}
          dimensionsDesktop={SIDE_DRAWER_DESKTOP}
          dimensionsMobile={SIDE_DRAWER_MOBILE}
        />
      </Box>
    </Stack>
  );
};
export const FriendsEdit = memo(FriendsEditUnmemoized);
