import { shuffleArray } from './shuffleArray';

export type GenerateNonAdjacentPositionsType = {
  maxIndex: number;
  numEntities: number;
  allowFirstIndex?: boolean;
  startIndex?: number;
};

export function generateNonAdjacentPositions({
  maxIndex,
  numEntities,
  allowFirstIndex = false,
  startIndex = 0,
}: GenerateNonAdjacentPositionsType) {
  if (maxIndex < 2) {
    return new Set<number>();
  }

  const availablePositions = Array.from(
    { length: maxIndex - startIndex + (allowFirstIndex ? 1 : 0) },
    (_, i) => {
      return i + startIndex + (allowFirstIndex ? 0 : 1);
    },
  );

  numEntities = Math.min(numEntities, availablePositions.length);
  shuffleArray(availablePositions);

  const newPositions = new Set<number>();
  for (const position of availablePositions) {
    if (!newPositions.has(position - 1) && !newPositions.has(position + 1)) {
      newPositions.add(position);
      if (newPositions.size === numEntities) {
        break;
      }
    }
  }

  return newPositions;
}
