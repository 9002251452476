import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { useAuthFlowDialog } from '../hooks/auth/useAuthFlowDialog';
import { useAuth } from './AuthContext';
import { sendVerificationEmail } from '../util/auth/sendVerificationEmail';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { ONE_MINUTE_MILLIS } from '../../functions/src/util/conversions';
import { setItem, getItem } from '../util/webStorage';

export const AUTH_MAIL_SEND_KEY = 'emailSendTime' as const;

export type EmailVerificationStatus = true | false | 'unknown';

export type VerifyEmailContextType = {
  emailSent: boolean;
  isEmailVerified: EmailVerificationStatus;
};

export type VerifyEmailProvider = {
  children: ReactNode;
};

export const VerifyEmailContext = createContext<VerifyEmailContextType>({
  emailSent: false,
  isEmailVerified: 'unknown',
});

export const useVerifyEmail = () => {
  return useContext(VerifyEmailContext);
};

const VerifyEmailProviderUnmemoized = ({ children }: VerifyEmailProvider) => {
  const [emailSent, setEmailSent] = useState(() => {
    const sentStartTime = getItem<string>(AUTH_MAIL_SEND_KEY);
    if (!sentStartTime) {
      return false;
    }
    return Date.now() - new Date(sentStartTime).getTime() < ONE_MINUTE_MILLIS;
  });
  const { user, uid, userData } = useAuth();
  const { open: openAuthDialog } = useAuthFlowDialog();

  const isEmailVerified = useMemo((): EmailVerificationStatus => {
    if (!uid || !userData?.id || !user?.email) {
      return 'unknown';
    }
    return user.emailVerified;
  }, [uid, user?.email, user?.emailVerified, userData?.id]);

  useEffect(() => {
    const handler = async () => {
      if (isEmailVerified !== false) {
        return;
      }
      try {
        openAuthDialog({ entryDialog: 'Verifying Email' });
        if (!emailSent) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await sendVerificationEmail(user!.email!);
          setEmailSent(true);
          setItem(AUTH_MAIL_SEND_KEY, new Date().toISOString());
          user?.reload?.();
          return;
        }
      } catch (error) {
        throw new HttpsError(
          'internal',
          'Failed to send authentication email',
          error,
        );
      }
    };
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSent, isEmailVerified, openAuthDialog, user?.reload, user?.email]);

  const value = useMemo(() => {
    return { emailSent, isEmailVerified };
  }, [emailSent, isEmailVerified]);

  return (
    <VerifyEmailContext.Provider value={value}>
      {children}
    </VerifyEmailContext.Provider>
  );
};

export const VerifyEmailProvider = memo(VerifyEmailProviderUnmemoized);
