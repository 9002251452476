import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDialog } from '../useDialog';
import { ChipUser } from '../../components/ChipUser';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { useAuth } from '../../contexts/AuthContext';

export const CANCEL_FRIEND_REQUEST_DIALOG_ID = 'CANCEL_FRIEND_REQUEST_DIALOG';

export type useCancelFriendRequestDialogParams = {
  username: string;
  imgUrl: string;
  userId: string;
};

export const useCancelFriendRequestDialog = ({
  username,
  imgUrl,
  userId,
}: useCancelFriendRequestDialogParams) => {
  const { uid } = useAuth();
  const { open: openDialog, close } = useDialog(
    CANCEL_FRIEND_REQUEST_DIALOG_ID,
  );

  const cancelFriendRequestFn = async () => {
    const { cancelFriendRequest } = await import('../../firebaseCloud');
    if (!uid) {
      return;
    }
    await cancelFriendRequest({ fromId: uid, toId: userId });
  };
  const open = () => {
    openDialog({
      title: 'Unsend Friend Request',
      description: (
        <Typography>
          Are you sure you want to unsend your friend request to
          <ChipUser
            username={username}
            avatarUrl={imgUrl}
            href={`/profile/${userId}`}
            sx={{ mx: 2 }}
          />
          ?
        </Typography>
      ),
      showCloseIcon: true,
      children: (
        <Stack direction="row" spacing={2} width="100%" alignItems="center">
          <LoadingButton
            onClick={async () => {
              await cancelFriendRequestFn();
              close();
            }}
            variant="contained"
            color="error"
            fullWidth
          >
            Unsend
          </LoadingButton>
          <Button fullWidth onClick={close} variant="contained" color="primary">
            Cancel
          </Button>
        </Stack>
      ),
    });
  };
  return { open, close };
};
