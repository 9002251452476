import Stack from '@mui/material/Stack';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { DialogNavbar } from 'src/components/dialog/DialogNavbar';
import { FC, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { useRemoveAuthQueryParams } from 'src/hooks/useRemoveAuthQueryParams';
import { useWizard } from 'src/components/wizard/Wizard';
import { AuthenticationPasswordStack } from '../AuthenticationPasswordStack';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import Typography from '@mui/material/Typography';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { resetPasswordWith } from '../../../util/auth/resetPasswordWith';
import { verifyResetCodeWith } from '../../../util/auth/verifyResetCodeWith';
import { PasswordResetButton } from '../PasswordResetButton';

const AuthenticationResetPasswordDialogUnmemoized: FC = () => {
  const { go } = useWizard();
  const {
    userCredentials: { password },
    isLoading,
    isValidated,
  } = useAuthSubmit();
  const [oobCode] = useRouterState({
    key: 'oobCode',
    location: 'queryParam',
  });
  const [email] = useRouterState({ key: 'email', location: 'queryParam' });
  const [error, setError] = useState<boolean>(false);
  const { removeAuthQueryParams } = useRemoveAuthQueryParams();

  const onSubmit = useCallback(async () => {
    if (!oobCode) {
      return;
    }
    try {
      await verifyResetCodeWith(oobCode);
      await resetPasswordWith(password, oobCode);
      go('Reset Password Success');
    } catch (e) {
      console.error(e);
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oobCode, password]);

  const errorButton = useMemo(() => {
    return (
      <>
        {error && email && (
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography color={'error'} variant="body2">
              Password reset failed, try again.
            </Typography>
            <PasswordResetButton
              email={decodeURIComponent(email)}
              text={'Resend email'}
              sx={{ textAlign: 'center' }}
            />
          </Stack>
        )}
      </>
    );
  }, [email, error]);

  return (
    <Stack width="100%">
      <DialogNavbar
        onClose={() => {
          removeAuthQueryParams();
          go(undefined);
        }}
      />

      <DialogBodyStandard
        title={'Reset Password'}
        description={
          <Stack width="100%" maxWidth="440px" alignSelf="center" spacing={6}>
            <Typography>Please enter new password below.</Typography>
            <AuthenticationPasswordStack />
          </Stack>
        }
      >
        <DialogActionsStandard
          buttons={[
            {
              isAsync: true,
              onClick: onSubmit,
              children: 'Reset Password',
              isLoading: isLoading,
              disabled: !(isValidated.password && isValidated.confirmPassword),
            },
          ]}
        />
        {errorButton}
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationResetPasswordDialog = memo(
  AuthenticationResetPasswordDialogUnmemoized,
);
