import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../catalogs/VerticalCarousel';
import { ReactNode, useMemo, FC } from 'react';
import { memo } from '../../../util/memo';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { NoSearchResults } from '../search/NoSearchResults';
import { useAuth } from '../../../contexts/AuthContext';
import { withAdInjection } from '../../../util/ads/withAdInjections';
import { FRIEND_DIMENSIONS } from '../../../../functions/src/config/ads/adDimensions';
import { renderHits } from '../../../util/algolia/renderHits';
import { usePreemptState } from '../../../contexts/algolia/PreemptStateContext';

export type FriendsSince = {
  _seconds: number;
  _nanoseconds: number;
};

export type FriendRequest = {
  status: string;
  message?: string;
};

export type FriendVerticalCarouselProps = Omit<
  VerticalCarouselProps,
  'Content'
> & {
  hits: FriendHit[];
  RenderFriendHit: FC<FriendHit>;
  NoFriends?: ReactNode;
};

const FriendVerticalCarouselUnmemoized = ({
  hits,
  onNearEnd,
  RenderFriendHit,
  Header,
  NoFriends,
  showDivider = false,
  containerSx = {},
  share = false,
  cardSpacing = '8px',
  ...props
}: FriendVerticalCarouselProps) => {
  const state = usePreemptState();
  const query = state?.query;
  const { userData } = useAuth();

  const Content = useMemo(() => {
    const hitsNotRejected = hits.filter((hit) => {
      if ('Node' in hit && 'key' in hit) {
        return;
      }
      return hit.receiver.friendRequest?.status !== 'rejected';
    });

    const hitsSorted = hitsNotRejected.sort((a, b) => {
      if (('Node' in a && 'key' in a) || ('Node' in b && 'key' in b)) {
        return 0;
      }
      const notSelfA =
        a.sender.username === userData?.username ? a.receiver : a.sender;
      const notSelfB =
        b.sender.username === userData?.username ? b.receiver : b.sender;
      return notSelfA.username > notSelfB.username
        ? 1
        : notSelfB.username > notSelfA.username
        ? -1
        : 0;
    });

    if (hitsSorted.length === 0 && !query) {
      return [
        {
          key: 'No Friends',
          Node: NoFriends,
        },
      ];
    }

    if (hitsSorted.length === 0) {
      return [
        {
          key: 'No Results',
          Node: <NoSearchResults />,
        },
      ];
    }

    return renderHits(hitsSorted, (hit) => {
      return <RenderFriendHit {...hit} />;
    });
  }, [hits, query, userData?.username, NoFriends, RenderFriendHit]);

  return (
    <VerticalCarousel
      Content={Content}
      onNearEnd={onNearEnd}
      Header={Header}
      share={share}
      containerSx={containerSx}
      showDivider={showDivider}
      cardSpacing={cardSpacing}
      {...props}
    />
  );
};

export const FriendVerticalCarousel = memo(FriendVerticalCarouselUnmemoized);

export const FRIENDS_AD_INTERVAL = 20 as const;
export const FRIEND_CARD_AD_BORDER_RADIUS = '10px' as const;

export const useFriendVerticalCarouselAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: FriendVerticalCarousel,
      adFrequency: FRIENDS_AD_INTERVAL,
      width: FRIEND_DIMENSIONS.width,
      height: FRIEND_DIMENSIONS.height,
      baseContainerId: containerId,
      adjustable: FRIEND_DIMENSIONS.adjustable,
      borderRadius: FRIEND_CARD_AD_BORDER_RADIUS,
    });
  }, [containerId]);
};
