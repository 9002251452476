import Box from '@mui/material/Box';
import { Explore } from './Explore';
import { Framework } from './Framework';
import { HeaderTabbed } from './header/HeaderTabbed';
import { memo } from '../util/memo';
import { ReactNode } from 'react';
import { usePendingCheckIns } from '../hooks/tournaments/usePendingCheckIns';
import { Sidebar } from './Sidebar';
import { useWebSocketWarningSnackbar } from '../hooks/useWebSocketWarningSnackbar';
import { useInitialScrollTo } from '../hooks/useInitialScrollTo';
import { useActionHandler } from '../hooks/useActionHandler';
import { useAppUrlOpen } from '../hooks/capacitor/useAppUrlOpen';
import { useOAuthCustomApp } from '../hooks/auth/useOAuthCustomApp';
import { useTemporaryChannelGroupSyncer } from '../hooks/messaging/useTemporaryChannelGroupSyncer';
import { useDeleteAccount } from '../hooks/useDeleteAccount';
// import { useShowAdBlock } from '../hooks/ads/useShowAdBlock';

export type MainLayoutProps = {
  children: ReactNode;
};

const MainLayoutUnmemoized = ({ children }: MainLayoutProps) => {
  useTemporaryChannelGroupSyncer();
  useWebSocketWarningSnackbar();
  usePendingCheckIns();
  useInitialScrollTo();
  useActionHandler();
  useAppUrlOpen();
  useOAuthCustomApp();
  useDeleteAccount();
  // useShowAdBlock();

  return (
    <Box height="100dvh" my={-6} sx={{ overflow: 'hidden' }}>
      <HeaderTabbed />
      <Framework
        Sidebar={<Sidebar />}
        Explore={<Explore>{children}</Explore>}
      />
    </Box>
  );
};

export const MainLayout = memo(MainLayoutUnmemoized);
