import { toTournamentId } from './urlParameterUtils';

export const TO_GROUP_FILTER_TYPE = {
  tournamentId: 'general',
} as const;

// corresponds to the query parameter key
export type TemporaryChannelGroupKey = 'tournamentId';
// View remarks for gameId in functions/src/types/firestore/User/ChannelGroup/index.ts
// | 'gameId';

export type ChannelGroupUrlIdentifier = Record<
  TemporaryChannelGroupKey,
  string
>;

// TODO: this will grow as we add more temporary channel group types
export const toIdentifiers = (url: string) => {
  const tournamentIdentifier = toTournamentId(url);
  // View remarks for gameId in functions/src/types/firestore/User/ChannelGroup/index.ts
  // const gameIdentifier = toGameId(url);
  return tournamentIdentifier ? [tournamentIdentifier] : [];
};
