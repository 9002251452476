export const calculateOnlineCount = (
  onlineStatus: Record<string, boolean>,
  friends: string[],
  fam: string[],
) => {
  const { onlineFamCount, onlineFriendsCount } = Object.keys(
    onlineStatus,
  ).reduce(
    (counts, userId) => {
      if (onlineStatus[String(userId)]) {
        if (fam.includes(userId)) {
          counts.onlineFamCount++;
        } else if (friends.includes(userId)) {
          counts.onlineFriendsCount++;
        }
      }
      return counts;
    },
    { onlineFamCount: 0, onlineFriendsCount: 0 },
  );

  return { onlineFamCount, onlineFriendsCount };
};
