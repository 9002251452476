import type { Timestamp } from 'firebase-admin/firestore';
import type { Message } from 'stream-chat';
import { TournamentPhase } from '../../Game/Tournament';

export const PERSONAL_TYPES = ['support', 'group', 'dm'] as const;
export type PersonalType = typeof PERSONAL_TYPES[number];

export const TOURNAMENT_FILTER_TYPES = ['general', 'match'] as const;
export type TournamentFilterType = typeof TOURNAMENT_FILTER_TYPES[number];
export const MATCH_CHANNEL_TYPE = 'match' as const;
export const GENERAL_CHANNEL_TYPE = 'general' as const;

export const CHANNEL_GROUP_PERMANENCE = [
  'temporary',
  'fixed',
  'unpinnable',
] as const;
export type ChannelGroupPermanence = typeof CHANNEL_GROUP_PERMANENCE[number];

export const FILTER_TYPES = [
  ...PERSONAL_TYPES,
  ...TOURNAMENT_FILTER_TYPES,
] as const;
export type FilterType = typeof FILTER_TYPES[number];

export type IncludesUser = { members: { $in: [string] } };
export type PersonalWithUsers = { members: { $eq: [string] } };
export type PersonalGroupFilter<TType extends PersonalType> = [
  { type: TType } & PersonalWithUsers,
];

/**
 * @remarks
 * In the future, always make the first index of the group filter array
 * the group filter that specifies a single Channel that must necessarily
 * be built as soon as the ChannelGroup is created.
 *
 * If there are multiple necessary Channels, you will need to modify the
 * implementation of ChannelFactory.buildNecessaryChannels
 */
export type GroupFilterMap = {
  Tournament: [
    {
      type: 'general';
      tournamentId: string;
      // gameId: string;
    },
    {
      type: 'match';
      tournamentId: string;
    } & IncludesUser,
  ];
  Support: PersonalGroupFilter<'support'>;
  Group: PersonalGroupFilter<'group'>;
  Dm: PersonalGroupFilter<'dm'>;
};

/**
 * @remarks
 * We need GetStream to introduce ability to duplicate channels in order
 * to bring back the gameId above and run a proper migration script,
 * tentatively callable/scripts/duplicateChannelsGetStream
 */

export type GroupFilter<
  TGroup extends keyof GroupFilterMap = keyof GroupFilterMap,
> = GroupFilterMap[TGroup];

export type GroupFilterElement<
  TGroup extends keyof GroupFilterMap = keyof GroupFilterMap,
  TIndex extends number = number,
> = GroupFilter<TGroup>[TIndex];

export type ChannelGroupUser = {
  username: string;
  image: string;
  userId: string;
  isAdmin?: boolean;
};

export type ChannelGroup<
  TGroupKey extends keyof GroupFilterMap = keyof GroupFilterMap,
  TTime = Timestamp,
> = {
  id: string;
  groupFilter: GroupFilter<TGroupKey>;
  members: ChannelGroupUser[];
  /**
   * @remarks
   * This is an aggregation of members necessary for running collectionGroup queries that proliferate users' username / image changes.
   */
  memberIds: string[];
  imgUrl: string;
  title: string;
  subtitle?: string;
  lastUpdated: TTime;
  hasMultipleChannels: boolean;
  isThisProliferation: boolean;
  messagesInitial?: Message[] | null;
  permanence: ChannelGroupPermanence;
  isChannelCreated: boolean;
  /**
   * @remarks
   * ID of the ChannelGroup that this document has replaced
   */
  replacedId?: string;
  date?: TTime;
  phase?: TournamentPhase;
};
