import { useState, useEffect } from 'react';
import { BREAKPOINTS } from '../styles/system';

export type DeviceType = 'mobile' | 'desktop' | 'any';

export const useDeviceType = (): DeviceType => {
  const [deviceType, setDeviceType] = useState<DeviceType>('any');

  useEffect(() => {
    const resize = () => {
      const width = window.innerWidth;
      if (width >= BREAKPOINTS.values.md) {
        setDeviceType('desktop');
      } else {
        setDeviceType('mobile');
      }
    };

    resize();

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return deviceType;
};
