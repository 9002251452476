import React, { useState, useCallback, useMemo, FormEvent } from 'react';
import { memo } from '../../util/memo';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { GradientIconButton } from '../gradients/GradientIconButton';
import { SxProps, useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';

export type ExpandableSearchProps = {
  expandedWidth?: string | number;
  placeholder?: string;
  query: string;
  onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  iconSx?: SxProps;
  sx?: SxProps;
  collapsable?: boolean; // New prop with a default value
};

const DEFAULT_EXPANDED_WIDTH_COLLAPSABLE = '250px' as const;

const ExpandableSearchUnmemoized: React.FC<ExpandableSearchProps> = ({
  expandedWidth: expandedWidthUnprepared = '100%',
  placeholder = 'Search...',
  query,
  onQueryChange,
  iconSx = {},
  sx = {},
  collapsable = true,
}) => {
  const expandedWidth = useMemo(() => {
    if (expandedWidthUnprepared === '100%' && collapsable) {
      return DEFAULT_EXPANDED_WIDTH_COLLAPSABLE;
    }
    return expandedWidthUnprepared;
  }, [collapsable, expandedWidthUnprepared]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const theme = useTheme();

  const toggleExpand = useCallback(() => {
    if (collapsable) {
      setIsExpanded((prev) => {
        return !prev;
      });
    }
  }, [collapsable]);

  const closeAndClear = useCallback(() => {
    if (collapsable) {
      setIsExpanded(false);
    }
    onQueryChange({
      target: { value: '' },
    } as React.ChangeEvent<HTMLInputElement>);
  }, [onQueryChange, collapsable]);

  const formSx = useMemo(() => {
    return {
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: '100px',
      width: isExpanded || !collapsable ? expandedWidth : undefined,
      transition: 'width 0.3s',
      backgroundColor: theme.palette.action.background,
      ...sx,
    } as const;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedWidth, isExpanded, sx, collapsable]);
  const preventSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  }, []);

  const iconButtonSx = useMemo(() => {
    return {
      ...iconSx,
    };
  }, [iconSx]);

  const expansion = useMemo(() => {
    if (!isExpanded && collapsable) {
      return undefined;
    }
    const hasQuery = query?.trim();
    const closeIconGradient =
      hasQuery || collapsable ? 'primary.horizontal' : 'disabled.horizontal';

    const width = `calc(${expandedWidth} - 44px)`;

    return (
      <Stack sx={{ flexDirection: 'row', width }}>
        <Input
          placeholder={placeholder}
          fullWidth
          disableUnderline
          autoFocus={collapsable}
          value={query}
          onChange={onQueryChange}
        />
        <GradientIconButton
          onClick={closeAndClear}
          disabled={!hasQuery && !collapsable}
          IconComponent={CloseIcon}
          gradientColor={closeIconGradient}
          sx={iconButtonSx}
        />
      </Stack>
    );
  }, [
    expandedWidth,
    isExpanded,
    collapsable,
    placeholder,
    query,
    onQueryChange,
    closeAndClear,
    iconButtonSx,
  ]);

  const expansionAnimated = useMemo(() => {
    if (!collapsable) {
      return expansion;
    }
    return (
      <Collapse in={isExpanded} orientation="horizontal">
        {expansion}
      </Collapse>
    );
  }, [collapsable, expansion, isExpanded]);

  return (
    <Stack component="form" onSubmit={preventSubmit} sx={formSx}>
      <GradientIconButton
        onClick={toggleExpand}
        IconComponent={SearchIcon}
        gradientColor="primary.horizontal"
        sx={iconButtonSx}
      />
      {expansionAnimated}
    </Stack>
  );
};

export const ExpandableSearch = memo(ExpandableSearchUnmemoized);
