import Chip, { ChipProps } from '@mui/material/Chip';
import { truncateIfTooLong } from 'src/util/truncate';
import { AvatarNext } from './AvatarNext';
import { OptionalWrapper } from './OptionalWrapper';
import { Link } from './Link';
import { useTheme } from '@mui/material/styles';
import { memo } from '../util/memo';

const SIZE_TO_WIDTH: Record<NonNullable<ChipProps['size']>, number> = {
  small: 15,
  medium: 24,
};

export type ChipUserProps = {
  username: string;
  avatarUrl: string;
  href?: string;
} & ChipProps;

function ChipUserUnmemoized({
  username,
  avatarUrl,
  onDelete = undefined,
  sx,
  href,
  size,
  ...propsChip
}: ChipUserProps) {
  const theme = useTheme();
  const avatarSize = SIZE_TO_WIDTH[String(size)];
  return (
    <OptionalWrapper
      doWrap={!!href}
      Wrapper={<Link href={!!href ? href : ''}></Link>}
    >
      <Chip
        onDelete={onDelete}
        avatar={
          <AvatarNext
            alt={username}
            src={avatarUrl}
            height={avatarSize}
            width={avatarSize}
          />
        }
        label={truncateIfTooLong(username)}
        variant="outlined"
        color="info"
        sx={{
          '.MuiChip-label': {
            color: '#fff',
          },
          '&:hover': { background: theme.palette.info.vertical },
          cursor: 'pointer',
          ...sx,
        }}
        size={size}
        {...propsChip}
      />
    </OptionalWrapper>
  );
}
export const ChipUser = memo(ChipUserUnmemoized);
