import { ComponentType, useMemo, FC, MemoExoticComponent } from 'react';
import { memo } from '../memo';
import { useAuth } from '../../contexts/AuthContext';
import { HitsProps } from '../ads/withAdInjections';

export type ExtractableComponent<THit, TProps extends HitsProps<THit>> =
  | ComponentType<TProps>
  | MemoExoticComponent<ComponentType<TProps>>;

/**
 * @remarks
 * You should avoid putting this inside a React Component body and instead put it
 * in the global scope.
 *
 * If you must use it inside a React Component body, you should useMemo.
 */
export const withoutUser = <
  THit extends Record<string, unknown>,
  TProps extends HitsProps<THit>,
>(
  WrappedComponent: ExtractableComponent<THit, TProps>,
) => {
  const ComponentWithoutUserUnmemoized: FC<TProps> = ({ hits, ...rest }) => {
    const { uid } = useAuth();

    const filteredHits = useMemo(() => {
      if (!uid) {
        return hits;
      }
      return hits.filter((hit) => {
        return 'id' in hit && hit.id !== uid;
      });
    }, [hits, uid]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const propsNew = { hits: filteredHits, ...rest } as any;
    return <WrappedComponent {...propsNew} />;
  };

  return memo(ComponentWithoutUserUnmemoized);
};
