import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { memo } from '../util/memo';
import type {
  Event as StreamEvent,
  Channel as StreamChannel,
  FormatMessageResponse,
} from 'stream-chat';
import { useStream } from './get-stream/StreamContext';
import {
  GroupFilter,
  PERSONAL_TYPES,
  PersonalType,
} from '../../functions/src/types/firestore/User/ChannelGroup';
import { toChannelGroupId } from '../../functions/src/util/messaging/toChannelGroupPath';
import { ChannelFetcher } from '../../functions/src/util/messaging/ChannelFetcher';
import { toChannelId } from '../../functions/src/util/messaging/toChannelId';
import { useRealtimeChannelGroups } from './RealtimeChannelGroupContext';
import { toGroupId } from '../../functions/src/util/messaging/toGroupId';
import { TournamentPhase } from '../../functions/src/types/firestore/Game/Tournament';
import { extractMessages } from '../util/messaging/extractMessages';
import { latestMessageUserId } from '../util/messaging/latestMessageUserId';
import { useGetStreamId } from '../hooks/messaging/useGetStreamId';

export const ONE = 1;

export type UnreadMessageCountGroup = {
  total: number;
  channels: {
    [cid: string]: {
      unreadCount: number;
      unseen: boolean;
      phase?: TournamentPhase;
      matchId?: string;
      tournamentId?: string;
    };
  };
};

export type UnreadMessageCountStructure = Record<
  string,
  UnreadMessageCountGroup
>;

export type UnreadMessageCountProps = {
  unreadMessageCount: UnreadMessageCountStructure;
  subscribedChannelGroupFilters: GroupFilter[];
  unionChannel: (channel: StreamChannel, channelGroupId: string) => void;
};

export type UnreadMessageCountProviderProps = {
  children: ReactNode;
};

export type UpdateUnreadCountParams = {
  unreadCountPrevious: UnreadMessageCountStructure;
  groupId: string;
  cid: string;
  count: number;
  unseen: boolean;
  phase?: TournamentPhase;
  matchId?: string;
  tournamentId?: string;
};

const UnreadMessageCountContext = createContext<UnreadMessageCountProps | null>(
  null,
);

export const useUnreadMessageCount = () => {
  const context = useContext(UnreadMessageCountContext);
  if (!context) {
    throw new Error(
      'useUnreadMessageCount must be used within a UnreadMessageCountProvider',
    );
  }
  return context;
};

const UnreadMessageCountProviderUnmemoized = ({ children }) => {
  const [unreadMessageCount, setUnreadMessageCount] =
    useState<UnreadMessageCountStructure>({});
  const groupUnsubscribesRef = useRef<Record<string, (() => void)[][]>>({});

  const { chatClient } = useStream();
  const { realtimeChannelGroups } = useRealtimeChannelGroups();
  const userId = useGetStreamId();

  const subscribedGroupFilters = useMemo(() => {
    return realtimeChannelGroups.map(({ groupFilter }) => {
      return groupFilter;
    });
  }, [realtimeChannelGroups]);

  const updateUnreadCount = useCallback(
    ({
      unreadCountPrevious,
      groupId,
      cid,
      count,
      phase,
      unseen,
      matchId,
      tournamentId,
    }: UpdateUnreadCountParams) => {
      const groupCount = unreadCountPrevious[`${groupId}`] ?? {
        total: 0,
        channels: {},
      };
      const channelCount = groupCount.channels[`${cid}`]?.unreadCount ?? 0;
      const channelCountNew = channelCount + count;
      const totalCountNew = groupCount.total + count;
      const totalCountCorrected = totalCountNew < 0 ? 0 : totalCountNew;
      const update = {
        unseen,
        unreadCount: channelCountNew,
        ...(!!phase && { phase }),
        ...(!!matchId && { matchId }),
        ...(!!tournamentId && { tournamentId }),
      };

      return {
        ...unreadCountPrevious,
        [groupId]: {
          total: totalCountCorrected,
          channels: {
            ...groupCount.channels,
            [cid]: update,
          },
        },
      };
    },
    [],
  );

  const incrementUnreadCount = useCallback(
    (
      groupId: string,
      cid: string,
      unseen: boolean,
      messages: FormatMessageResponse[],
    ) => {
      const messageLatestUserId = latestMessageUserId(messages);
      const isOwnMessage = userId === messageLatestUserId;

      if (isOwnMessage) {
        return;
      }

      setUnreadMessageCount((prev) => {
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: ONE,
          groupId,
          cid,
          unseen,
        });
      });
    },
    [updateUnreadCount, userId],
  );

  const resetUnreadCount = useCallback(
    (
      event: StreamEvent,
      groupId: string,
      cid: string,
      messages: FormatMessageResponse[],
    ) => {
      const { user: eventUser, type } = event;
      const messageLatestUserId = latestMessageUserId(messages);
      const readOwnMessage =
        eventUser?.id === messageLatestUserId && type === 'message.read';

      if (readOwnMessage) {
        return;
      }

      setUnreadMessageCount((prev) => {
        const unreadCount =
          prev[`${groupId}`]?.channels[`${cid}`].unreadCount ?? 0;
        const unreadCountSubtract = -unreadCount;
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: unreadCountSubtract,
          groupId,
          cid,
          unseen: false,
        });
      });
    },
    [updateUnreadCount],
  );

  const addChannel = useCallback(
    (channel: StreamChannel, channelGroupId: string) => {
      const { data, cid, state } = channel;
      const { matchId, tournamentId, groupName } = data as {
        matchId?: string;
        tournamentId?: string;
        groupName?: string;
      };
      const groupId = toGroupId(channelGroupId, groupName, matchId);
      const phase = realtimeChannelGroups.find((channelGroup) => {
        return channelGroup.id === channelGroupId;
      })?.phase;
      const { read } = state;
      const unseen = !read[String(userId)]?.last_read_message_id;

      const { unreadCount } = state;

      setUnreadMessageCount((prev) => {
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: unreadCount,
          groupId,
          cid,
          phase,
          unseen: !unseen,
          matchId,
          tournamentId,
        });
      });

      const onMessageNew = () => {
        const messages = extractMessages(channel);
        incrementUnreadCount(groupId, cid, unseen, messages);
      };

      const onMessageRead = (event: StreamEvent) => {
        const messages = extractMessages(channel);
        resetUnreadCount(event, groupId, cid, messages);
      };

      channel.on('message.new', onMessageNew);
      channel.on('message.read', onMessageRead);

      return [
        () => {
          return channel.off('message.new', onMessageNew);
        },
        () => {
          return channel.off('message.read', onMessageRead);
        },
      ];
    },
    [
      incrementUnreadCount,
      realtimeChannelGroups,
      resetUnreadCount,
      updateUnreadCount,
      userId,
    ],
  );

  const unionChannel = useCallback(
    (channel: StreamChannel, channelGroupId: string) => {
      const { data, cid } = channel;
      const { matchId, groupName } = data as {
        matchId?: string;
        groupName?: string;
      };
      const groupId = toGroupId(channelGroupId, groupName, matchId);
      const unreadCount = unreadMessageCount[`${groupId}`];
      const unreadCountChannel = unreadCount?.channels[`${cid}`];
      if (!!unreadCountChannel) {
        return;
      }
      addChannel(channel, channelGroupId);
    },
    [addChannel, unreadMessageCount],
  );

  useEffect(() => {
    const handler = async () => {
      if (!chatClient) {
        return;
      }

      const channelUnsubscribes: Record<string, (() => void)[][]> = {};
      const channelPromises = subscribedGroupFilters.map(
        async (groupFilter) => {
          const channelGroupId = toChannelGroupId(groupFilter);

          if (!!groupUnsubscribesRef.current[`${channelGroupId}`]) {
            return;
          }

          const channelType = groupFilter[0].type;
          const isPersonal = PERSONAL_TYPES.includes(
            channelType as PersonalType,
          );
          const channelId = `${channelType}:${toChannelId(groupFilter[0])}`;

          const fetcher = new ChannelFetcher(chatClient);

          const channels = isPersonal
            ? [await fetcher.fetch(channelId)]
            : await fetcher.fetchGroup(groupFilter);

          channelUnsubscribes[`${channelGroupId}`] = channels.map((channel) => {
            if (!channel) {
              return [];
            }
            return addChannel(channel, channelGroupId);
          });
        },
      );
      await Promise.all(channelPromises);

      groupUnsubscribesRef.current = {
        ...groupUnsubscribesRef.current,
        ...channelUnsubscribes,
      };
    };

    handler();
    return () => {
      Object.values(groupUnsubscribesRef.current).forEach(
        (channelUnsubscribes) => {
          channelUnsubscribes.forEach((unsubscribePair) => {
            unsubscribePair.forEach((unsubscribe) => {
              unsubscribe();
            });
          });
        },
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient, subscribedGroupFilters.length]);

  const memoizedValue = useMemo(() => {
    return {
      unreadMessageCount,
      subscribedChannelGroupFilters: subscribedGroupFilters,
      unionChannel,
    };
  }, [subscribedGroupFilters, unionChannel, unreadMessageCount]);

  return (
    <UnreadMessageCountContext.Provider value={memoizedValue}>
      {children}
    </UnreadMessageCountContext.Provider>
  );
};

export const UnreadMessageCountProvider = memo(
  UnreadMessageCountProviderUnmemoized,
);
