import { useState } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/system/Stack';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useRemoveFriendDialog } from '../../../hooks/friends/useRemoveFriendDialog';
import { useAuth } from '../../../contexts/AuthContext';
import { useIndexingHits } from '../../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { FriendListItemLayout } from './FriendListItemLayout';

export type FriendCardEditProps = FriendHit & { isFam?: boolean };

const FriendCardEditUnmemoized = ({
  isFam = false,
  ...hit
}: FriendCardEditProps) => {
  const { uid } = useAuth();
  const { objectID, sender, receiver } = hit;
  const friendToRender = sender.userId === uid ? receiver : sender;
  const { username, imgUrl, userId, status } = friendToRender;
  const { open: openRemoveFriendDialog } = useRemoveFriendDialog({
    imgUrl,
    username,
    userId,
  });

  const [isLockedToggleFam, setIsLockedToggleFam] = useState<boolean>(false);
  const { add, remove } = useIndexingHits();

  const toggleFam = async () => {
    if (!uid || !!isLockedToggleFam) {
      return;
    }

    try {
      setIsLockedToggleFam(true);
      const friendLayout = convertToHash(
        fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid),
      );
      const famLayout = convertToHash(
        fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['fam'], uid),
      );
      remove(isFam ? famLayout : friendLayout, objectID);
      add(isFam ? friendLayout : famLayout, hit);

      const { updateFam } = await import('../../../firebaseCloud');
      await updateFam({ fromId: uid, toId: userId });
    } finally {
      setIsLockedToggleFam(false);
    }
  };

  return (
    <FriendListItemLayout {...{ username, imgUrl, userId, status }}>
      <Stack direction="row">
        <GradientIconButton
          IconComponent={isFam ? FavoriteIcon : FavoriteBorderIcon}
          onClick={toggleFam}
        />
        <GradientIconButton
          IconComponent={HighlightOffIcon}
          onClick={() => {
            return openRemoveFriendDialog();
          }}
        />
      </Stack>
    </FriendListItemLayout>
  );
};

export const FriendCardEdit = memo(FriendCardEditUnmemoized);
