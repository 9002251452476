export function swap<
  K extends string | number | symbol,
  V extends string | number | symbol,
>(flatObj: Record<K, V>): Record<V, K> {
  const swappedObj: { [key: string]: string } = {};
  Object.keys(flatObj).forEach((key) => {
    // eslint-disable-next-line security/detect-object-injection
    const val = flatObj[key];
    // eslint-disable-next-line security/detect-object-injection
    swappedObj[val] = key;
  });
  return swappedObj as Record<V, K>;
}
