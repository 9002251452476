import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SxProps, useTheme } from '@mui/material/styles';
import { JSXElementConstructor, ReactElement, ReactNode, useMemo } from 'react';
import { OptionalWrapper } from '../OptionalWrapper';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { GradientIconButton } from '../gradients/GradientIconButton';
import Box from '@mui/material/Box';
import { memo } from '../../util/memo';

export type SignInMenuItemProps = {
  label: string;
  onClick: () => void | Promise<void>;
  index?: number;
  IconComponent?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  disableIcon?: boolean;
  isSelected?: boolean;
  Wrapper?: ReactElement<
    {
      sx?: SxProps | undefined;
      children?: ReactNode;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    string | JSXElementConstructor<any>
  >;
};

export const SignInMenuItem = memo(function SignInMenuItemUnmemoized({
  label,
  onClick,
  IconComponent,
  disableIcon,
  isSelected,
  Wrapper,
  index,
}: SignInMenuItemProps) {
  const theme = useTheme();

  const icon = useMemo(() => {
    return (
      !!IconComponent && (
        <GradientIconButton
          IconComponent={IconComponent}
          size="small"
          sxOuter={{ p: 0 }}
          disabled={disableIcon}
        />
      )
    );
  }, [IconComponent, disableIcon]);

  return (
    <OptionalWrapper
      doWrap={!!Wrapper}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Wrapper={Wrapper!}
    >
      <MenuItem
        sx={{
          pt: index === 0 ? 3 : undefined,
          backgroundColor: isSelected
            ? theme.palette.background.elevation[20]
            : undefined,
        }}
      >
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          onClick={onClick}
        >
          {icon}
          <Box>{label}</Box>
        </Stack>
      </MenuItem>
    </OptionalWrapper>
  );
});
