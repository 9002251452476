import { useEffect } from 'react';
import { useDeleteAccountDialog } from './useDeleteAccountDialog';
import { useAuth } from '../contexts/AuthContext';
import { useDeleteAccountRouter } from './useDeleteAccountRouter';

export const useDeleteAccount = () => {
  const { uid } = useAuth();
  const { deleteAccount, close } = useDeleteAccountRouter();
  const { open: openDeleteAccountDialog } = useDeleteAccountDialog();
  useEffect(() => {
    if (uid && deleteAccount === 'true') {
      openDeleteAccountDialog(close);
    }
  }, [close, deleteAccount, openDeleteAccountDialog, uid]);
};
