import { ExchangeRatesPrices } from '../../types/firestore/ExchangeRates/Prices';
import { NULL_ADDRESS_EVM } from '../../types/firestore/User/Payout';

export type CompositeTokenId = `${number}-0x${string}`;

export type PriceCrypto = {
  wei: number | string | bigint;
  cryptocurrency: string;
  isWrapped: boolean;
  isUsdPegged: boolean;
};

export type ToFiat = (
  { wei, cryptocurrency, isWrapped, isUsdPegged }: PriceCrypto,
  fiatCurrency?: string,
) => number;

function wrappedToNative(compositeId: CompositeTokenId): CompositeTokenId {
  const chainId = compositeId.split('-')[0] as `${number}`;
  return `${chainId}-${NULL_ADDRESS_EVM}`;
}

export const COMPOSITE_TOKEN_ID_REGEX = /^\d+-0x[0-9a-fA-F]+$/;

function isCompositeTokenId(
  cryptocurrency: string,
): cryptocurrency is CompositeTokenId {
  return COMPOSITE_TOKEN_ID_REGEX.test(cryptocurrency);
}

export class CryptoToFiatConverter {
  public constructor(
    private rates: Record<string, number>,
    private currencyBase: ExchangeRatesPrices['currencyBase'],
  ) {}

  public convert: ToFiat = (
    { wei, cryptocurrency, isWrapped = false, isUsdPegged = false },
    fiatCurrency = this.currencyBase,
  ) => {
    return (
      (Number(wei) / 1e18) *
      this.rateCrypto(cryptocurrency, isWrapped, isUsdPegged) *
      this.rates[String(fiatCurrency)]
    );
  };

  private rateCrypto(
    cryptocurrency: string,
    isWrapped: boolean,
    isUsdPegged: boolean,
  ) {
    const cryptocurrencyLowercased = cryptocurrency.toLowerCase();
    const cryptocurrencyNative =
      isWrapped && isCompositeTokenId(cryptocurrencyLowercased)
        ? wrappedToNative(cryptocurrencyLowercased)
        : cryptocurrencyLowercased;

    return isUsdPegged ? 1 : this.rates[String(cryptocurrencyNative)];
  }
}
