import { memo } from '../../../util/memo';
import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { useWizard } from 'src/components/wizard/Wizard';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { DialogActionsStandard } from '../../dialog/DialogActionsStandard';
import { useRegistrationFunctions } from '../../../hooks/tournaments/useRegistrationFunctions';
import { useAlertDialog } from '../../../hooks/useAlertDialog';
import { extractErrorMessage } from '../../../../functions/src/util/sentinel/extractErrorMessage';
import { NoticeParagraphed } from '../../NoticeParagraphed';

const NOT_CHECKIN_PHASE = 'NOT_CHECKIN_PHASE' as const;

const RulesCheckInDialogUnmemoized = () => {
  const { rulesAndRegulations, date } = useTournamentRegistration();
  const { checkIn } = useRegistrationFunctions();
  // const { userData } = useAuth();
  const { go } = useWizard();
  const { open: openAlertDialog } = useAlertDialog(NOT_CHECKIN_PHASE);
  const checkInSelf = async () => {
    try {
      // if (!userData?.username || userData?.username?.length > 25) {
      //   return go('CreateUsernameDialog');
      // }
      await checkIn(false);
      go('CheckedInDialog');
    } catch (e) {
      go(undefined);
      if (extractErrorMessage(e).includes('not within the check-in window')) {
        openAlertDialog({
          title: 'Check-In Unsucessful',
          description:
            'The check-in phase for this tournament has either not started or has ended',
        });
      }
    }
  };

  const format = useDateFormatter({
    separator: ', ',
  });
  const startDateFormatted = format(date);

  return (
    <DialogBodyStandard
      title={'Tournament Rules'}
      description={
        <Box>
          <Box
            sx={{
              textAlign: 'center',
              backgroundImage: 'none',
              display: 'inline-block',
              maxWidth: '540px',
            }}
          >
            <Typography variant="h6" sx={{ display: 'inline' }}>
              The tournament begins{' '}
            </Typography>
            <GradientTypography
              variant="h6"
              gradientColor="warning.vertical"
              sx={{ display: 'inline' }}
            >
              {startDateFormatted}
            </GradientTypography>
            <Typography variant="h6" sx={{ display: 'inline' }}>
              . In order to participate, you must agree to the rules below:
            </Typography>
          </Box>

          {rulesAndRegulations && (
            <NoticeParagraphed text={rulesAndRegulations} />
          )}
        </Box>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            children: 'Accept',
            isAsync: true,
            type: 'submit',
            onClick: async () => {
              await checkInSelf();
            },
            endIcon: <CheckCircleOutlineIcon />,
            color: 'secondary',
            sx: { flex: 2 },
          },
          {
            children: 'Cancel',
            isAsync: false,
            onClick: () => {
              return go(undefined);
            },
            color: 'error',
            sx: { textTransform: 'uppercase', flex: 1, ml: 1 },
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const RulesCheckInDialog = memo(RulesCheckInDialogUnmemoized);
