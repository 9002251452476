import { useState, useCallback } from 'react';
import { memo } from '../util/memo';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useAuth } from '../contexts/AuthContext';
import { useRouterState } from '../hooks/routing/useRouterState';
import { GradientIconButton } from './gradients/GradientIconButton';
import { ChannelGroupSidebarList } from './messaging/channel/ChannelGroupSidebarList';
import { ChatMenu } from './messaging/ChatMenu';
import { useSocialDrawer } from '../hooks/useSocialDrawer';
import { useActiveChannelGroup } from '../contexts/ActiveChannelGroupContext';
import { useGuardSignIn } from '../hooks/useGuardSignIn';
import { MESSAGING_SIGN_IN_TITLE } from './AppNavigationTabs';
import { SideDrawerToggle } from './SideDrawerToggle';
import { FriendPaneOption } from './social-drawer/friends/FriendsPane';

export const SIDEBAR_WIDTH = 76 as const;

const SidebarUnmemoized = () => {
  const theme = useTheme();
  const { uid } = useAuth();
  const [_, setFriendsTab] = useRouterState({ key: 'friends' });
  const [__, setAppNavigationTab] = useRouterState({ key: 'nav' });
  const { closeChannelGroup } = useActiveChannelGroup();
  const { openSignInGuard } = useGuardSignIn();
  const { openSocialDrawer } = useSocialDrawer();
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);

  const openMenuGuarded = useCallback(
    ({ currentTarget }) => {
      if (!uid) {
        return openSignInGuard(MESSAGING_SIGN_IN_TITLE);
      }
      return setAnchorEl(currentTarget);
    },
    [openSignInGuard, uid],
  );

  const closeMenu = useCallback(() => {
    return setAnchorEl(null);
  }, []);

  const create = useCallback(() => {
    closeChannelGroup();
    openSocialDrawer();
    setAppNavigationTab('Friends');
    setFriendsTab('Chat' as FriendPaneOption);
    closeMenu();
  }, [
    closeChannelGroup,
    closeMenu,
    openSocialDrawer,
    setFriendsTab,
    setAppNavigationTab,
  ]);

  return (
    <Stack
      width="76px"
      height="100%"
      alignItems="center"
      justifyContent={!uid ? 'flex-end' : 'space-between'}
      sx={{
        background: theme.palette.background.elevationSolid[4],
        borderLeft: {
          xs: 'none',
          lg: `1px solid ${theme.palette.primary.dark}`,
        },
        boxShadow: { xs: theme.shadows[4], lg: 'none' },
        pb: 3,
      }}
    >
      <SideDrawerToggle />
      <Box height="100%">
        <ChannelGroupSidebarList />
      </Box>
      <Box
        sx={{
          '& .MuiSvgIcon-root': {
            m: '6px !important',
          },
          pb: { xs: 2, lg: 0 },
        }}
      >
        <GradientIconButton
          IconComponent={BorderColorIcon}
          onClick={openMenuGuarded}
          withBorder={true}
        />
      </Box>
      {anchorEl && (
        <ChatMenu
          anchorEl={anchorEl}
          createChat={create}
          onClose={closeMenu}
          onCreateSupport={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{ ml: -15 }}
        />
      )}
    </Stack>
  );
};

export const Sidebar = memo(SidebarUnmemoized);
