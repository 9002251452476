import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { LogoSlogan } from '../header/LogoSlogan';
import { Link } from '../Link';

export function Footer() {
  const theme = useTheme();

  const linkTypographySx = {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.secondary,
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          background: 'rgba(0, 14, 33, 0.8)',
          //backdropFilter: 'blur(50px)',
          width: '100%',
          borderTop: `1px solid ${theme.palette.primary.dark}`,
          padding: { xs: 3, lg: '10px 32px' },
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%">
          <LogoSlogan />
          <Stack
            sx={{
              flexDirection: { xs: 'column', lg: 'row-reverse' },
              alignItems: { xs: 'flex-end', lg: 'center' },
            }}
          >
            <Stack direction="row">
              <Link
                href="/docs/tos.html"
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: 'none',
                }}
              >
                <span style={{ textDecoration: 'underline' }}>Copyright</span> ©
                {currentYear} BluMint Inc. All rights reserved.
              </Link>
            </Stack>
            <Stack direction="row" spacing={2} mx={{ xs: 0, lg: 4 }}>
              <Link
                href="/docs/tos.html"
                style={{
                  ...linkTypographySx,
                }}
              >
                Terms Of Use
              </Link>
              <Link href="/docs/privacy.html" style={{ ...linkTypographySx }}>
                Privacy Policy
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </footer>
  );
}
