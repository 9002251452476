import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { ChannelGroupSidebar } from './ChannelGroupSidebar';
import { useRealtimeChannelGroups } from '../../../contexts/RealtimeChannelGroupContext';
import { sortChannelPreviews } from '../../../util/messaging/sortChannelPreviews';

const ChannelGroupSidebarListUnmemoized = () => {
  const theme = useTheme();
  const { realtimeChannelGroups, containerRef } = useRealtimeChannelGroups();

  const channelPreviewsSorted = useMemo(() => {
    return sortChannelPreviews(realtimeChannelGroups);
  }, [realtimeChannelGroups]);

  const ChannelGroupAvatars = useMemo(() => {
    return channelPreviewsSorted.map(
      (
        channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
        index,
        array,
      ) => {
        const isLastChannelGroup = index === array.length - 1;
        const isTournamentNextChannelGroup =
          index < array.length - 1 &&
          array[index + 1].groupFilter[0].type === 'general';

        return (
          <ChannelGroupSidebar
            key={channelGroup.id}
            channelGroup={channelGroup}
            isLastTournamentChannelGroup={
              !isLastChannelGroup && !isTournamentNextChannelGroup
            }
          />
        );
      },
    );
  }, [channelPreviewsSorted]);

  return (
    <Stack
      ref={containerRef}
      alignItems="center"
      sx={{
        height: 'calc(100% - 80px)',
        width: '100%',
        overflowY: 'scroll',
        ...theme.palette.scrollbars.invisible,
      }}
    >
      {ChannelGroupAvatars}
    </Stack>
  );
};

export const ChannelGroupSidebarList = memo(ChannelGroupSidebarListUnmemoized);
