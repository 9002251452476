import { KeyedNode, OrNode } from '../../../functions/src/types/Hit';
import { AdapexAdContainer } from '../../components/ads/AdapexAdContainer';

export const injectAds = <TElement,>({
  elements,
  adPositions,
  adWidth,
  adHeight,
  baseContainerId,
  adjustable,
  borderRadius,
}: {
  elements: TElement[];
  adPositions: Set<number>;
  adWidth: number;
  adHeight: number;
  baseContainerId: string;
  adjustable?: 'width' | 'height';
  borderRadius?: string;
}) => {
  return elements.reduce(
    (accumulator: OrNode<TElement>[], element, index, array) => {
      if (adPositions.has(index)) {
        const adIndex = accumulator.filter(isPersonaAd).length;
        const uniqueKey = `ad-${adIndex}`;
        const containerId = `${baseContainerId}-contentId-${adIndex}`;
        const nextElement = array[index + 1] as { dateDay?: number };
        const prevElement = array[index - 1] as { dateDay?: number };

        const dateDay = prevElement?.dateDay || nextElement?.dateDay;

        const adNode = {
          key: uniqueKey,
          Node: (
            <AdapexAdContainer
              key={uniqueKey}
              baseContainerId={containerId}
              width={adWidth}
              height={adHeight}
              adjustable={adjustable}
              borderRadius={borderRadius}
            />
          ),
          dateDay,
        };
        accumulator.push(adNode);
      }

      accumulator.push(element);

      return accumulator;
    },
    [] as OrNode<TElement>[],
  );
};

export function isPersonaAd(item: unknown): item is KeyedNode {
  return (
    !!item &&
    typeof item === 'object' &&
    'Node' in item &&
    !!item.Node &&
    typeof item.Node === 'object' &&
    'type' in item.Node &&
    item.Node.type === AdapexAdContainer
  );
}
