import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../util/memo';
import { useTheme } from '@mui/material/styles';
import { GradientIcon } from './gradients/GradientIcon';
import { GradientButton } from './gradients/GradientButton';
import LoginIcon from '@mui/icons-material/Login';
import { useAuthFlowDialog } from '../hooks/auth/useAuthFlowDialog';

export type SignUpPromptedProps = {
  prompt: string;
};

const SignUpPromptedUnmemoized = ({ prompt }: SignUpPromptedProps) => {
  const theme = useTheme();
  const { open: openAuthDialog } = useAuthFlowDialog();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={4}
    >
      <GradientIcon
        IconComponent={LoginIcon}
        sx={{ height: '48px', width: '48px' }}
      />
      <Typography variant="subtitle1" color="text.secondary">
        {prompt}
      </Typography>
      <GradientButton
        variant="contained"
        onClick={() => {
          openAuthDialog();
        }}
        sx={{
          height: '42px',
          width: '181px',
          borderRadius: '24px',
          '.MuiTypography-root': {
            WebkitTextFillColor: `${theme.palette.text.primary} !important`,
          },
        }}
      >
        Sign up
      </GradientButton>
    </Stack>
  );
};

export const SignUpPrompted = memo(SignUpPromptedUnmemoized);
