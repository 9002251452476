import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import {
  FriendVerticalCarousel,
  useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { FriendCarouselHeader } from './FriendCarouselHeader';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendCardRequest } from '../../cards/friend/FriendCardRequest';
import {
  UserHit,
  UserVerticalCarousel,
  UserVerticalCarouselProps,
  useUserVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/UserVerticalCarousel';
import { NoFriends } from '../../profile/header/NoFriends';
import { useTheme } from '@mui/material/styles';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { useNotificationCount } from '../../../contexts/NotificationCountContext';
import {
  SIDE_DRAWER_DESKTOP,
  SIDE_DRAWER_MOBILE,
} from '../../../../functions/src/config/ads/adDimensions';
import { useMobile } from '../../../hooks/useMobile';
import {
  FRIENDS_ADD_AD_DESKTOP,
  FRIENDS_ADD_AD_1,
} from '../../../../functions/src/util/ads/adIds';
import { AdapexAdDynamic } from '../../ads/AdapexAdDynamic';
import { withoutUser } from '../../../util/algolia/withoutUser';
import { OrNode } from '../../../../functions/src/types/Hit';
import { USER_CONFIGURE_OPTIONS } from '../../../../functions/src/util/algolia/config/user';

export const FRIEND_TABS_HEIGHT_DESKTOP = 80 as const;
export const FRIEND_TABS_HEIGHT_MOBILE = 36 as const;

const FRIENDS_HITS_PER_PAGE = 20 as const;

const FRIEND_CONFIGURE_OPTIONS = {
  ...USER_CONFIGURE_OPTIONS,
  hitsPerPage: FRIENDS_HITS_PER_PAGE,
} as const;

const FriendsAddUnmemoized = () => {
  const { uid } = useAuth();

  const theme = useTheme();

  const { friendRequests: notificationCount } = useNotificationCount();

  const isMobile = useMobile();

  // NOTE: Algolia has restrictions on the query complexity for performance reasons. That's why the friendRequestFilter is incomplete and we apply the necessary checks before rendering the card later on instead.
  // https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/combining-boolean-operators/

  const UserVerticalCarouselAds = useUserVerticalCarouselAds(
    FRIENDS_ADD_AD_DESKTOP,
  );

  const AllUserVerticalCarousel = useMemo(() => {
    return withoutUser<OrNode<UserHit>, UserVerticalCarouselProps>(
      isMobile ? UserVerticalCarousel : UserVerticalCarouselAds,
    );
  }, [isMobile, UserVerticalCarouselAds]);

  const FriendVerticalCarouselAds = useFriendVerticalCarouselAds(
    FRIENDS_ADD_AD_DESKTOP,
  );

  const AllFriendVerticalCarousel = useMemo(() => {
    return isMobile ? FriendVerticalCarousel : FriendVerticalCarouselAds;
  }, [isMobile, FriendVerticalCarouselAds]);

  const UsersCatalogWrapper = useCallback(
    (props: Omit<UserVerticalCarouselProps, 'Header' | 'showDivider'>) => {
      return (
        <AllUserVerticalCarousel
          {...props}
          Header={
            <FriendCarouselHeader title="ADD FRIENDS" separateSearch={true} />
          }
          showDivider={false}
        />
      );
    },
    [AllUserVerticalCarousel],
  );

  const RequestsCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof AllFriendVerticalCarousel>,
        | 'RenderFriendHit'
        | 'Header'
        | 'NoFriends'
        | 'showDivider'
        | 'containerSx'
      >,
    ) => {
      return (
        <AllFriendVerticalCarousel
          RenderFriendHit={FriendCardRequest}
          {...props}
          Header={
            <FriendCarouselHeader
              title="FRIEND REQUESTS"
              notificationCount={notificationCount}
            />
          }
          NoFriends={<NoFriends variant="friend requests" isSelf={false} />}
          showDivider={false}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AllFriendVerticalCarousel, notificationCount],
  );

  const friendRequestsFilter = uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friendRequest'], uid)
    : ''; // Undefined would return everyone's friend requests
  const requestsConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendRequestsFilter,
    };
  }, [friendRequestsFilter]);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          lg: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 4,
        pb: { xs: 2, lg: 0 },
      }}
    >
      <Stack
        spacing={6}
        justifyContent={'space-between'}
        sx={{
          px: 4,
          height: {
            xs: `calc(100% - ${SIDE_DRAWER_MOBILE.height}px)`,
            lg: `calc(100% - ${SIDE_DRAWER_DESKTOP.height}px)`,
          },
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={UsersCatalogWrapper}
            configureOptions={FRIEND_CONFIGURE_OPTIONS}
            index="CONTENT"
          />
        </Box>
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={RequestsCatalogWrapper}
            configureOptions={requestsConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
      <Box position="absolute" sx={{ bottom: 0 }}>
        <AdapexAdDynamic
          baseContainerId={FRIENDS_ADD_AD_1}
          dimensionsDesktop={SIDE_DRAWER_DESKTOP}
          dimensionsMobile={SIDE_DRAWER_MOBILE}
        />
      </Box>
    </Stack>
  );
};
export const FriendsAdd = memo(FriendsAddUnmemoized);
