import { RecaptchaVerifier } from 'firebase/auth';
import { extractDigits } from '../../../functions/src/util/extractDigits';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export const phoneNumberSignIn = async (
  phoneNumber: string,
  appVerifier: RecaptchaVerifier,
) => {
  const authImport = import('../../config/firebase-client/auth');
  const { auth } = await authImport;
  const firebaseAuthImport = import('firebase/auth');

  const {
    signInWithPhoneNumber,
    linkWithPhoneNumber,
    reauthenticateWithPhoneNumber,
  } = await firebaseAuthImport;
  const currentUser = auth.currentUser;
  const numberCleaned = extractDigits(phoneNumber);
  if (!currentUser || currentUser.isAnonymous) {
    return await signInWithPhoneNumber(auth, numberCleaned, appVerifier);
  }

  const providerData = currentUser.providerData;
  const foundNumber = providerData.some(
    ({ providerId, phoneNumber: providerPhoneNumber }) => {
      return providerId === 'phone' && providerPhoneNumber === numberCleaned;
    },
  );

  if (foundNumber) {
    return await reauthenticateWithPhoneNumber(
      currentUser,
      numberCleaned,
      appVerifier,
    );
  }

  const { findOwner } = await import('../../firebaseCloud/auth/findOwner');
  const { data: numberIsOwnedBy } = await findOwner({
    type: 'phone',
    value: numberCleaned,
  });

  if (numberIsOwnedBy && numberIsOwnedBy.ownerId !== currentUser.uid) {
    throw new HttpsError(
      'already-exists',
      'phone-number-already-exists',
      `phoneNumber: ${phoneNumber}, belongs to ${numberIsOwnedBy.ownerId}`,
    );
  }

  return await linkWithPhoneNumber(currentUser, numberCleaned, appVerifier);
};
