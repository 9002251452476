import { ErrorableInput } from '../../contexts/AuthSubmitContext';

export const DEFAULT_ERROR_MESSAGE =
  'Oops, something went wrong, please try again.';

export type ErrorTypes = Record<string, string>;

export const AUTH_ERRORS_MAP: Partial<{
  [K in ErrorableInput]: string[];
}> = {
  password: ['wrong-password'],
  phoneNumber: ['invalid-phone-number', 'phone-number-already-exists'],
  username: ['username-unavailable'],
  confirmPassword: ['do-not-match'],
  email: [
    'verification-issue',
    'invalid-email',
    'email-already-in-use',
    'email-linked-to-twitter',
    'email-linked-to-google',
    'email-linked-to-discord',
    'email-linked-to-twitch',
    'email-does-not-have-password-credentials',
  ],
  confirmationCode: ['did-not-receive', 'invalid-verification-code'],
  agreements: ['conditions-not-met'],
  generic: [
    'default',
    'exists-with-different-credential',
    'requires-recent-login',
    'no-such-provider',
    'already-in-use',
    'too-many-requests',
  ],
};

export const ERROR_CODE_TO_MESSAGE: ErrorTypes = {
  'wrong-password': 'Incorrect password. Please try again.',
  'invalid-phone-number':
    'The entered phone number is invalid. Please provide a valid number.',
  'phone-number-already-exists':
    'This phone number is already in use. Please choose another.',
  'username-unavailable':
    'This username is unavailable. Please select a different one.',
  'do-not-match': 'Passwords do not match. Please ensure they are identical.',
  'verification-issue':
    'There was an issue verifying your email. Please retry or contact support.',
  'invalid-email':
    'The entered email is invalid. Please provide a valid email address.',
  'did-not-receive':
    'If you did not receive a confirmation code, please request a new one.',
  'invalid-verification-code':
    'The entered verification code is incorrect. Please try again.',
  'conditions-not-met': 'Please confirm all conditions to proceed.',
  'already-in-use': 'These credentials are already in use by another user.',
  'requires-recent-login': 'For security reasons, please sign in again.',
  'email-already-in-use':
    'This email is already in use. Please choose a different one.',
  'email-linked-to-password': 'This email is already linked with a password.',
  'no-such-provder':
    'The provider you are attempting to remove is no longer linked to your account.',
  'email-linked-to-twitter':
    'The email is already linked to a Twitter account.',
  'email-linked-to-google': 'The email is already linked to a Google account.',
  'email-linked-to-discord':
    'The email is already linked to a Discord account.',
  'email-already-exists':
    'The email that you are trying to create an account with is already taken. Please use a different one.',
  'email-linked-to-twitch': 'The email is already linked to a Twitch account.',
  'exists-with-different-credential':
    'The email is already taken by an exisitng BluMint account. Please sign into that account and then link this sign-in method to it.',
  'too-many-requests':
    'Your account has been temporarily locked due to too many failed login attempts. Please try again later or kindly reset your password.',
  default: DEFAULT_ERROR_MESSAGE,
};

export const extractErrorCode = (e: unknown) => {
  const errorCodes = Object.values(AUTH_ERRORS_MAP).flat();
  const errorCode = errorCodes.find((code) => {
    return ((e as any)?.message as string).includes(code);
  });
  if (!errorCode) {
    return 'default';
  }
  return errorCode;
};

export const extractMethodFromCode = (errorCode: string) => {
  for (const key in AUTH_ERRORS_MAP) {
    if (AUTH_ERRORS_MAP.hasOwnProperty(key)) {
      const errorTypes = AUTH_ERRORS_MAP[String(key)];
      const foundKey = Object.keys(errorTypes).find((errorTypeKey) => {
        return errorTypes[String(errorTypeKey)].includes(errorCode);
      });
      if (foundKey) {
        return key;
      }
    }
  }
  throw new Error(`Key not found for error code: ${errorCode}`);
};
