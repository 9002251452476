import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { ImageOptimized } from '../image/ImageOptimized';
import { GradientTypography } from '../gradients/GradientTypography';
import { ReactNode } from 'react';

export type UniversalAppStatusProps = {
  imgUrl: string;
  description: string;
  title: string;
  size?: number;
  subText?: boolean;
  errorCode?: number;
  showButton?: boolean;
  prefetchImage?: boolean;
  children?: ReactNode;
};

export const UniversalAppStatus = memo(function UniversalAppStatusUnmemoized({
  imgUrl,
  errorCode,
  title,
  description,
  size = 400,
  subText = true,
  showButton = true,
  prefetchImage = false,
  children,
}: UniversalAppStatusProps) {
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent="center"
      spacing={8}
      sx={{
        height: '100%',
        maxHeight: '620px',
        width: '100%',
      }}
    >
      <ImageOptimized
        src={imgUrl}
        alt="blumint mascot"
        width={size}
        height={size}
        priority={prefetchImage}
      />
      <Stack
        spacing={2}
        sx={{
          textAlign: 'center',
          zIndex: 1,
          background: theme.palette.background.elevation[10],
          borderRadius: '20px',
          width: '100%',
          maxWidth: '500px',
          p: 8,
        }}
      >
        <GradientTypography
          variant="h4"
          gradientColor="primary.vertical"
          sx={{ textTransform: 'uppercase' }}
        >
          {errorCode ? `${errorCode?.toString()} - ${title}` : `${title}`}
        </GradientTypography>
        <Typography variant="h6">{description}</Typography>
        {subText && (
          <Typography
            variant="h6"
            sx={{ pb: 2 }}
          >{`Let's get you out of here.`}</Typography>
        )}
        {showButton && (
          <Button size="large" variant="contained" color="primary" href="/">
            GO TO HOME
          </Button>
        )}
        {children}
      </Stack>
    </Stack>
  );
});
