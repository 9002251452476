import { useCallback, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useWalletAuth } from './useWalletAuth';
import { ResetUserItemsParams } from '../../../functions/src/callable/user/resetUserItems.f';
import { useWagmiFunc } from './useWagmiFunc';
import { useWizardDialogConfirmation } from '../wizard/useWizardDialogConfirmation';
import { truncateIfTooLong } from '../../util/truncate';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useSelectedAddress } from './useSelectedAddress';
import WalletIcon from '../../components/icons/WalletIcon';
import { SignInMenuItemProps } from '../../components/header/SignInMenuItem';

export const useMenuItemsWallet = () => {
  const { userData, uid } = useAuth();

  const { resetWallet } = useWalletAuth();
  const userAddresses = userData?.addresses;
  const { address: addressSelected } = useSelectedAddress();

  const resetItems = async () => {
    if (!uid) {
      return;
    }

    const { resetUserItems } = await import(
      'src/firebaseCloud/user/resetUserItems'
    );
    const resetUserItemsParams: ResetUserItemsParams = { userId: uid };
    await resetUserItems(resetUserItemsParams);
  };

  const disconnect = useWagmiFunc('disconnect');

  const removeAddress = useCallback(
    async (address: string) => {
      if (!uid) {
        return;
      }

      try {
        const { removeAddress: removeWalletAddress } = await import(
          '../../firebaseCloud/user/removeAddress'
        );
        await Promise.all([
          resetItems(),
          disconnect(),
          removeWalletAddress({ address }),
        ]);

        return [
          ['message', `Address removed successfully!`],
          ['success', 'true'],
        ];
      } catch (error) {
        return [
          ['message', 'Error removing wallet address. Please try again.'],
          ['success', 'false'],
        ];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uid],
  );

  const { open: openConfirmationWizardDialog } = useWizardDialogConfirmation();
  const openRemoveWalletWizard = useCallback(
    (address: string) => {
      openConfirmationWizardDialog({
        onConfirm: () => {
          return removeAddress(address);
        },
        title: 'Remove Wallet Address',
        description: `Are you sure you want to remove ${truncateIfTooLong(
          address,
        )} from your account?`,
      });
    },
    [openConfirmationWizardDialog, removeAddress],
  );
  const MenuItemsWallet: SignInMenuItemProps[] = useMemo(() => {
    if (!userAddresses?.length) {
      return [];
    }
    return [...userAddresses].flatMap((address) => {
      const canRemove = !!uid && uid.toLowerCase() !== address;
      const isSelected = addressSelected === address;

      const resetItem = {
        label: truncateIfTooLong(address),
        onClick: resetWallet,
        IconComponent: WalletIcon,
        isSelected,
      };
      if (!canRemove) {
        return [resetItem];
      }

      const removeItem = {
        label: truncateIfTooLong(address),
        onClick: () => {
          return openRemoveWalletWizard(address);
        },
        IconComponent: LinkOffIcon,
        isSelected,
      };
      return [resetItem, removeItem];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAddresses, uid]);

  return { MenuItemsWallet };
};
//TODO: See if you can refactor this
