import stringify from 'json-stringify-safe';

const sortArray = <TArr>(array: TArr[]) => {
  if (
    array.every((item) => {
      return typeof item === 'number';
    })
  ) {
    (array as number[]).sort((a, b) => {
      return a - b;
    });
    return;
  }
  array.sort();
};

function sortObject<TObject extends Record<string, unknown>>(object: TObject) {
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (Array.isArray(object[String(key)])) {
        sortArray(object[String(key)] as unknown[]);
      } else if (
        typeof object[String(key)] === 'object' &&
        object[String(key)] !== null
      ) {
        sortObject(object[String(key)] as Record<string, unknown>);
      }
    }
  }
}

export function sortObjectArrays<TObject extends Record<string, unknown>>(
  object: TObject,
): TObject {
  const objectCopy = JSON.parse(stringify(object));
  sortObject(objectCopy);
  return objectCopy;
}
