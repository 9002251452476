import { ReactNode, memo as reactMemo, ComponentType } from 'react';
import isEqual from 'fast-deep-equal/react';

export type Props<T extends object = object> = {
  [key: string]: Date | T | ReactNode;
  sx?: T;
};

function isObject(
  value: unknown,
): value is Record<string | number | symbol, unknown> {
  return value !== null && typeof value === 'object';
}

function blumintAreEqual<TProps>(prevProps: TProps, nextProps: TProps) {
  if (!isObject(prevProps) || !isObject(nextProps)) {
    return prevProps === nextProps;
  }

  const keys = Object.keys({ ...prevProps, ...nextProps });

  return keys.every((key) => {
    const prevValue = prevProps[String(key)];
    const nextValue = nextProps[String(key)];

    if (
      key === 'sx' &&
      typeof prevValue === 'object' &&
      typeof nextValue === 'object'
    ) {
      return isEqual(prevValue, nextValue);
    }

    if (prevValue instanceof Date && nextValue instanceof Date) {
      return prevValue.getTime() === nextValue.getTime();
    }

    return prevValue === nextValue;
  });
}

const memo = <TProps>(
  Component: ComponentType<TProps>,
  areEqual?: (prevProps: TProps, nextProps: TProps) => boolean,
) => {
  const eitherEqual = (prevProps: TProps, nextProps: TProps) => {
    return (
      blumintAreEqual(prevProps, nextProps) ||
      areEqual?.(prevProps, nextProps) ||
      false
    );
  };

  return reactMemo(Component, eitherEqual);
};

export { memo };
