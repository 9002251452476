import Typography from '@mui/material/Typography';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../gradients/GradientTypography';
import { GradientIcon } from '../gradients/GradientIcon';
import { SxProps, useTheme } from '@mui/material/styles';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';

export type TournamentPanelV3Props = {
  TitleIconComponent?: OverridableComponent<SvgIconTypeMap>;
  title?: string | ReactNode;
  description?: string | ReactNode;
  Content: ReactNode;
  wrapperSx?: SxProps;
};

export const TournamentPanelV3 = memo(function TournamentPanelV3Unmemoized({
  TitleIconComponent,
  title,
  description,
  Content,
  wrapperSx,
}: TournamentPanelV3Props) {
  const theme = useTheme();
  return (
    <Stack width={'100%'} sx={{ ...theme.panels[1], ...wrapperSx }}>
      <Stack
        direction="row"
        gap={2}
        justifyContent="flex-start"
        alignItems="center"
      >
        {TitleIconComponent && (
          <GradientIcon
            sx={{ width: 24, height: 24 }}
            IconComponent={TitleIconComponent}
            gradientColor="primary.horizontal"
          />
        )}
        {title &&
          (typeof title === 'string' ? (
            <GradientTypography
              gradientColor="primary.vertical"
              variant="h6"
              fontWeight={600}
              textTransform="uppercase"
            >
              {title}
            </GradientTypography>
          ) : (
            title
          ))}
      </Stack>
      {description &&
        (typeof description === 'string' ? (
          <Typography color="text.secondary" variant="body1" sx={{ py: 1 }}>
            {description}
          </Typography>
        ) : (
          description
        ))}
      <Stack pt={2}>{Content}</Stack>
    </Stack>
  );
});
