import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useConvertedHits } from '../../hooks/algolia/useConvertedHits';
import { useMergeIndexingHits } from '../../hooks/algolia/useMergeIndexingHits';
import type { useInfiniteHits } from 'react-instantsearch';

export const useCustomHits = (
  infiniteHits: ReturnType<typeof useInfiniteHits>,
) => {
  const { items, showMore, isLastPage } = infiniteHits;
  const filters = infiniteHits.results?._state.filters;

  const hitsConverted = useConvertedHits(items);
  const hitsMerged = useMergeIndexingHits(hitsConverted, filters);

  const canLoadMoreRef = useRef(true);
  const loadMore = useCallback(() => {
    if (!canLoadMoreRef.current || !showMore) {
      return;
    }
    canLoadMoreRef.current = false;
    showMore();
  }, [showMore]);

  useEffect(() => {
    canLoadMoreRef.current = !isLastPage;
  }, [items.length, isLastPage]);

  return useMemo(() => {
    return { hits: hitsMerged, loadMore };
  }, [hitsMerged, loadMore]);
};
