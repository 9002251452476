import { extractErrorMessage } from '../../../functions/src/util/sentinel/extractErrorMessage';

export const INVITE_ERROR_MESSAGE_LIBRARY: Record<string, string> = {
  'Invitation Invalid':
    'It seems the person you are inviting, or the person inviting you, does not exist. Please verify and try again.',
  'Tournament or Invitee not found':
    'No tournament was found matching your criteria. Please try again.',
  'Invalid invite token':
    'Your invite URL appears to be invalid. Please check the link and try again.',
  'Inviter not on team':
    'The person who invited you seems to have left the team. Please consider joining another team or creating your own.',
  'Team is full':
    'Unfortunately, the team you are trying to join is currently full. Consider joining a different team.',
  'Team not found':
    'The team you are trying to join does not appear to exist. Please try to join a different team.',
  'Invitee taken':
    'The person you invited is already on a team or is a team captain.',
  'Already on current team': 'You are already a member of this team.',
  'Tournament has already ended':
    'Unfortunately, the tournament you have been invited to has already ended.',
  'User account deleted':
    'Unfortunately, the user you are inviting has deleted their account.',
  'Unknown Error':
    'An unknown error has occurred. Please contact our support team for assistance.',
};

export const getInviteErrorMessage = (error: unknown) => {
  if (typeof error === 'object' && !!error && 'message' in error) {
    const errorMessage = extractErrorMessage(error);
    const errorCodes = Object.keys(INVITE_ERROR_MESSAGE_LIBRARY);
    const foundError =
      errorCodes.find((errorCode) => {
        return errorMessage.includes(errorCode);
      }) || 'Unknown Error';

    return {
      title: foundError,
      message: INVITE_ERROR_MESSAGE_LIBRARY[String(foundError)],
    };
  }
  return {
    title: 'Unknown Error',
    message: INVITE_ERROR_MESSAGE_LIBRARY['Unknown Error'],
  };
};
