/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import stringify from 'json-stringify-safe';

const originalConsoleMethods = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
};

const IGNORED_MESSAGES = {
  error: [
    /^Warning: Cannot update a component \(\`\%s\`\) while rendering a different component \(\`\%s\`\)/,
    /^AlgoliaLayoutBidirectionalUnmemoized/,
    /TypeError: Cannot read properties of undefined \(reading 'slice'\).*at AlgoliaSearchHelper/,
  ] as const,
  warn: [
    /^\[InstantSearch.js\]: The `searchFunction` option is deprecated. Use `onStateChange` instead.$/,
  ] as const,
  log: [] as const,
  info: [] as const,
};

const shouldIgnoreMessage = (message: string, regexes: readonly RegExp[]) => {
  return regexes.some((regex) => {
    return regex.test(message);
  });
};

const createConsoleMethod = (method: keyof typeof originalConsoleMethods) => {
  return (...args: any[]) => {
    const argsStringified = args.map((arg) => {
      if (typeof arg !== 'object') {
        return String(arg);
      }
      try {
        return stringify(arg);
      } catch (error) {
        return '';
      }
    });
    const message = argsStringified.join('');
    if (shouldIgnoreMessage(message, IGNORED_MESSAGES[String(method)])) {
      return;
    }
    originalConsoleMethods[String(method)](...args);
  };
};

console.log = createConsoleMethod('log');
console.info = createConsoleMethod('info');
console.warn = createConsoleMethod('warn');
console.error = createConsoleMethod('error');
