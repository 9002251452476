import { FC } from 'react';
import { memo } from '../../util/memo';
import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../gradients/GradientTypography';
import { Competition } from 'functions/src/types/firestore/Game/Competition';
import { TournamentPanelV3 } from '../tournaments/TournamentPanelV3';

const Countdown = dynamic(
  () => {
    return import('react-countdown');
  },
  {
    ssr: false,
  },
);

const Counter = memo(function CounterUnmemoized({
  label,
  counter,
}: {
  label: string;
  counter: number;
}) {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography variant="h2" fontSize={50}>
        {counter.toString().padStart(2, '0')}
      </Typography>
      <GradientTypography
        gradientColor="primary.vertical"
        variant="h6"
        textTransform="uppercase"
      >
        {label}
      </GradientTypography>
    </Stack>
  );
});

export type EventTimeProps = Pick<Competition<Date>, 'date'> & {
  title: string;
};

export type CountdownRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

// NOTE: memoization breaks <Countdown> dependency, as does use of hooks
// eslint-disable-next-line @blumintinc/blumint/require-memo
const CountdownRenderer: FC<CountdownRendererProps> = ({
  days,
  hours,
  minutes,
  seconds,
}) => {
  const counters = [
    {
      label: 'days',
      counter: days,
    },
    {
      label: 'hrs',
      counter: hours,
    },
    {
      label: 'mins',
      counter: minutes,
    },
    { label: 'secs', counter: seconds },
  ];
  return (
    <Stack direction="row" gap={4}>
      {counters.map(({ label, counter }) => {
        return (
          <Box
            key={label}
            sx={{
              backgroundColor: 'rgba(2, 39, 80, 0.80)',
              padding: { xs: '6px 12px', sm: '8px 16px' },
              borderRadius: '10px',
              width: '76px',
            }}
          >
            <Counter label={label} counter={counter} />
          </Box>
        );
      })}
    </Stack>
  );
};

export const EventTime = memo(function EventTimeUnmemoized({
  date,
  title,
}: EventTimeProps) {
  return (
    <TournamentPanelV3
      title={
        <GradientTypography
          variant="h6"
          fontWeight={600}
          fontSize={18}
          gradientColor="primary.vertical"
          textTransform="uppercase"
        >
          {title}
        </GradientTypography>
      }
      Content={
        <Stack
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Countdown date={date} renderer={CountdownRenderer} />
        </Stack>
      }
    />
  );
});
