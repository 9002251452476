import { memo } from '../../util/memo';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

const GameTag = memo(({ tag }: { tag: string }) => {
  return (
    <Chip size="small" variant="outlined" label={tag} sx={{ boxShadow: 1 }} />
  );
});

export type GameTagsProps = {
  tags: string[];
};

export const GameTags = memo(function GameTagsUnmemoized({
  tags,
}: GameTagsProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 2,
      }}
    >
      {tags.map((tag) => {
        return <GameTag key={tag} tag={tag} />;
      })}
    </Box>
  );
});
