import { IndividualSessionStorage } from '../../../functions/src/types/firestore/User/SessionStorage';
import { useSessionState } from '../useSessionState';

export const useTemporaryChannelGroups = () => {
  const [temporaryChannelGroups, setTemporaryChannelGroups] = useSessionState<
    keyof IndividualSessionStorage
  >('temporaryChannelGroups');

  return { temporaryChannelGroups, setTemporaryChannelGroups };
};
