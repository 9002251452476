import { useEffect, useRef, useState } from 'react';
import { useAdVisibility } from './useAdVisibility';
import { generateNewContainerId } from '../../util/ads/generateNewContainerId';
import { track } from '@vercel/analytics';
import { useAuth } from '../../contexts/AuthContext';
import { useWindowFocus } from '../useWindowFocus';
import { DeviceType, useDeviceType } from '../useDeviceType';

export const MIN_ADS_IMPRESSION_MS = 1000;

const pickAdDisplayMs = (deviceType: DeviceType) => {
  return deviceType === 'mobile' ? 16000 : 45000;
};

export const useAdRefresh = ({
  baseContainerId,
  aspectRatio,
  adUnitId,
}: {
  baseContainerId: string;
  aspectRatio: string;
  adUnitId: string;
}) => {
  const [containerId, setContainerId] = useState(
    `${baseContainerId}-temporary`,
  );
  const impressionTrackedRef = useRef(false);

  const deviceType = useDeviceType();
  const adDisplayMs = pickAdDisplayMs(deviceType);

  const remainingTimeRef = useRef(adDisplayMs);
  const { uid: userId } = useAuth();

  const { isAlmostVisible, isRefreshable } = useAdVisibility(containerId);
  const isWindowFocused = useWindowFocus();

  useEffect(() => {
    const newContainerId = generateNewContainerId(containerId);
    setContainerId(newContainerId);
    impressionTrackedRef.current = false;
    remainingTimeRef.current = adDisplayMs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio, adDisplayMs]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    let startTime: number;

    const startTimeout = () => {
      startTime = Date.now();
      timeoutId = setTimeout(() => {
        impressionTrackedRef.current = false;
        const newContainerId = generateNewContainerId(containerId);
        setContainerId(newContainerId);
        remainingTimeRef.current = adDisplayMs;
      }, remainingTimeRef.current);
    };

    const pauseTimeout = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        const elapsedTime = Date.now() - startTime;
        remainingTimeRef.current -= elapsedTime;
      }
    };

    if (isRefreshable && isWindowFocused) {
      startTimeout();
    }

    return () => {
      pauseTimeout();
    };
  }, [isWindowFocused, isRefreshable, containerId, adDisplayMs]);

  const environment = process.env.NODE_ENV;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (isRefreshable && isWindowFocused && !impressionTrackedRef.current) {
      timeoutId = setTimeout(() => {
        const [location, , , contentId] = containerId.split('-');

        track('impression', {
          ...(userId && { userId }),
          containerId,
          adUnitId,
          location,
          device: deviceType,
          ...(contentId && { contentId }),
          environment,
        });
        impressionTrackedRef.current = true;
      }, MIN_ADS_IMPRESSION_MS);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [
    containerId,
    adUnitId,
    userId,
    isWindowFocused,
    isRefreshable,
    deviceType,
    environment,
  ]);

  return {
    containerId,
    impressionTrackedRef,
    remainingTimeRef,
    isAlmostVisible,
  };
};
