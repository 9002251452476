import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useCallback } from 'react';
import { memo } from '../../util/memo';
import { LoadingButton } from './LoadingButton';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useAuth } from 'src/contexts/AuthContext';
import { sendVerificationEmail } from '../../util/auth/sendVerificationEmail';

export const ResendEmailButton = memo(function ResendEmailButtonUnmemoized() {
  const { user } = useAuth();
  const [resent, setResent] = useState(false);

  const [seconds, setSeconds] = useState<number | null>(60);
  const [isCountingDown, setIsCountingDown] = useState(true);

  const startCountdown = useCallback(() => {
    setSeconds(60);
    setIsCountingDown(true);
  }, []);

  useEffect(() => {
    if (seconds === null) return;

    if (seconds === 0) {
      setIsCountingDown(false);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        return prevSeconds !== null ? prevSeconds - 1 : null;
      });
    }, 1000);

    return () => {
      return clearInterval(interval);
    };
  }, [seconds]);

  const resendEmail = useCallback(async () => {
    if (!user?.email || user?.emailVerified) {
      return;
    }
    try {
      await sendVerificationEmail(user.email);
      setResent(true);
    } catch (e) {
      console.error(e);
    }
  }, [user?.email, user?.emailVerified]);
  return (
    <LoadingButton
      variant="contained"
      size="large"
      onClick={async () => {
        await resendEmail();
        startCountdown();
      }}
      fullWidth
      disabled={isCountingDown}
      endIcon={resent ? <CheckCircleOutlineRoundedIcon /> : undefined}
      sx={{ flex: 2, justifyContent: 'center' }}
    >
      <Stack direction="row" spacing={1} width="100%">
        <Typography variant="h6">Resend Email</Typography>
        {isCountingDown && <Typography variant="h6">: {seconds}</Typography>}
      </Stack>
    </LoadingButton>
  );
});
