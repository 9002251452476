import Box from '@mui/material/Box';
import { useTheme, SxProps } from '@mui/material/styles';
import { Fragment, useMemo, FC, ReactNode } from 'react';
import { memo } from '../../../util/memo';
// import { ShareUrlToClipboard } from '../headers/ShareUrlToClipboard';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { ViewMoreChip } from '../headers/ViewMoreChip';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import { KeyedNode } from '../../../../functions/src/types/Hit';

export const BUFFER_BEFORE_FETCH = 4;

export type VerticalCarouselProps = {
  viewMoreLink?: string;
  Header?: ReactNode;
  Content: KeyedNode[];
  onNearEnd?: () => void;
  cardSpacing?: string;
  buffer?: number;
  share?: boolean;
  containerSx?: SxProps;
  showDivider?: boolean;
  background?: number;
  infiniteLoad?: boolean;
};

const VerticalCarouselUnmemoized: FC<VerticalCarouselProps> = ({
  Content,
  Header,
  onNearEnd,
  viewMoreLink,
  cardSpacing = '0px',
  buffer = BUFFER_BEFORE_FETCH,
  // share = true,
  containerSx = {},
  showDivider = true,
  background = 8,
  infiniteLoad = true,
}) => {
  const { ref } = useIntersectionObserver({
    onIntersect: () => {
      if (onNearEnd && infiniteLoad) {
        onNearEnd();
      }
    },
  });

  const MemoizedContent = useMemo(() => {
    if (Content.length === 0) {
      return null;
    }

    return Content.map(({ key, Node }, index) => {
      return (
        <Fragment key={key}>
          <Box
            ref={index === Content.length - buffer - 1 ? ref : null}
            pb={index < Content.length - 1 ? cardSpacing : '0px'}
          >
            {Node}
          </Box>
          {index < Content.length - 1 && showDivider && (
            <Divider variant="middle" />
          )}
        </Fragment>
      );
    });
  }, [Content, ref, buffer, cardSpacing, showDivider]);

  const theme = useTheme();

  return (
    <Stack
      direction="column"
      height="100%"
      sx={{
        background: theme.palette.background.elevation[Number(background)],
        py: 2,
        borderRadius: '10px',
        ...containerSx,
      }}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack
          direction="row"
          spacing={2}
          flex={1}
          sx={{ alignItems: 'center' }}
        >
          {Header}
          {viewMoreLink && <ViewMoreChip href={viewMoreLink}></ViewMoreChip>}
        </Stack>
        {/* {share && <ShareUrlToClipboard></ShareUrlToClipboard>} */}
      </Stack>
      <Stack
        sx={{
          height: '100%',
          borderRadius: '10px',
          overflowY: 'auto',
          ...theme.palette.scrollbars.invisible,
        }}
      >
        {MemoizedContent}
      </Stack>
    </Stack>
  );
};

export const VerticalCarousel = memo(VerticalCarouselUnmemoized);
