import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import { Game } from 'functions/src/types/firestore/Game';
import { ShareLikedCount } from '../ShareLikedCount';
import { GameTags } from '../game-info/GameTags';
import { Link } from '../Link';
import { useEffect, useState } from 'react';
import { memo } from '../../util/memo';
import { toKebabCase } from '../../util/string';
// import { CardSlot } from './CardSlot';
import { RecentGame } from 'functions/src/types/firestore/User';
import { Optional } from 'utility-types';
import Typography from '@mui/material/Typography';
import { formatRelative } from 'src/util/dates/Dates';
import { ImageOverlayed } from '../ImageOverlayed';

export type GameCard2Props = Pick<
  Game<Date>,
  'title' | 'imgUrl' | 'id' | 'likes'
> &
  Optional<Pick<Game<Date>, 'tags'>> &
  Optional<Pick<RecentGame<Date>, 'hoursPlayed' | 'lastPlayed'>> & {
    children?: JSX.Element | JSX.Element[];
  };

export const GameCard2 = memo(function GameCard2Unmemoized(
  props: GameCard2Props,
) {
  const { id, title, imgUrl, tags, children, likes, hoursPlayed, lastPlayed } =
    props;

  const [gameAbsoluteUrl, setGameAbsoluteUrl] = useState('');

  const gameUrl = `/game/${toKebabCase(title)}`;

  useEffect(() => {
    if (!window) {
      return;
    }
    setGameAbsoluteUrl(`${window.location.hostname}${gameUrl}`);
  }, [gameUrl]);

  const gamePath = `Game/${id}`;

  return (
    <>
      {/* <CardSlot content="game"> */}
      <Box
        sx={{
          border: 1,
          borderRadius: '10px',
          borderColor: 'primary.mid',
          borderWidth: '2px',
        }}
      >
        <Link href={gameUrl} style={{ textDecoration: 'none' }}>
          <ImageOverlayed
            src={imgUrl}
            height={300}
            width={526}
            alt={title}
            sx={{ mx: 1, mt: 1 }}
          />
        </Link>
        <CardActions>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {!!tags && (
                <Box sx={{ pt: 1.5, pb: 1.5 }}>
                  <GameTags tags={tags || []} />
                </Box>
              )}
              {(!!hoursPlayed || hoursPlayed === 0) && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {Math.round(hoursPlayed)} hours played
                </Typography>
              )}
              {!!lastPlayed && (
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{ textTransform: 'uppercase' }}
                >
                  Last played: {formatRelative(lastPlayed)}
                </Typography>
              )}
            </Box>
            <ShareLikedCount
              shareUrl={gameAbsoluteUrl}
              path={gamePath}
              likesOriginal={likes}
              guardTitle={`Like this game?`}
              likeButtonSx={{
                width: '30px',
                height: '30px',
                mt: '-4px',
                mb: '-8px',
                ml: '-4px',
              }}
              likeCountProps={{
                variant: 'body1',
                mt: '4px',
              }}
              shareButtonSx={{
                width: '26px',
                height: '26px',
                mt: '-4px',
                mb: '-8px',
                mx: '-8px',
              }}
            />
          </Box>
        </CardActions>
        {children}
      </Box>
      {/* </CardSlot> */}
    </>
  );
});
